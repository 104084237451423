import React from 'react' 

import Loader from '../LinearLoading/LinearLoading'

export default function FullScreenLoader(props) {

    const style = {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        zIndex: '998'
    }

    const lStyle= {
        paddingTop: '25%',
        width:'50%',
        paddingLeft:'25%'
    }

    let loader = null
    if(props.visible) {
        loader = (
            <div style={style}>
                <div style={lStyle}>
                    <Loader />
                </div>
            </div>
        )
    }

    return (
        <>
            {loader}
        </>
    )
}
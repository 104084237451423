import React from 'react'
import classes from './Confirmacion.module.css'

import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import Confirmation from '../../UI/Assets/Birrete.svg'
import Neswletter from '../../UI/Assets/buzon.svg'

import { FaFacebookF, FaTwitter, FaLinkedinIn, FaLink } from 'react-icons/fa'

export default function Confirmacion() {

    function dateBuilder() {
        const months = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
            'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']

        const date = new Date()
        return ` ${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
    }

    return (
        <>
            <Navbar />
            {/* THANK YOU */}
            <div className={classes.container}>
                <div className={classes.left}>
                    <img src={Confirmation} alt="GraciasPorLaDonacion" />
                </div>
                <div className={classes.right}>
                    <strong style={{ fontSize: '1.25em', textAlign: 'left' }}>
                        ¡Gracias por apoyar a la educación en México!
                    </strong>
                </div>
            </div>

            {/* TRANSACTION */}
            <div className={classes.transaction}>
                <small className={classes.print}>Imprimir recibo</small>
                <div className={classes.transactionCard}>
                    <div className={classes.transactionDetails} >
                        <strong>ID de la transacción: </strong>
                        23912312312
                    </div>
                    <div className={classes.payerDetails} >
                        Amin Wejebe Shanahan <br />
                        <strong>$200.00 MXN</strong>
                    </div>
                    <div className={classes.payerDate} >
                        Fecha de Facturación:
                        {dateBuilder()}
                    </div>
                </div>
            </div>

            <div className={classes.banner} ></div>
            {/* SOCIAL */}
            <div className={classes.social} >
                <div>Invita a tus amigos a cambiar el futuro de nuestro país</div>
                <span><FaFacebookF /></span>
                <span><FaTwitter /></span>
                <span><FaLinkedinIn /></span>
                <span><FaLink /></span>
            </div>

            {/* NEWSLETTER */}
            <div className={classes.container}>
                <div className={classes.left}>
                    <img src={Neswletter} alt="Newsletter" />
                </div>
                <div className={classes.right} >
                    <strong>Recibirás en tu correo noticias de Anáhuac Mayab</strong>
                </div>
            </div>

            {/*<form className={classes.newsletter} style={{paddingBottom:'150px'}}>
                <input placeholder="Tu correo electrónico" />
                <button><FaPaperPlane /></button>
            </form>*/}

            <div style={{ paddingBottom: '450px' }}>

            </div>

            <Footer />

        </>
    )
}
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import API from '../../API'
import fonaton from '../../UI/Assets/Fonaton.svg'
import Loader from '../../UI/CircularLoader/CircularLoader'
import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import classes from './Login.module.css'
import logo from "../../UI/Assets/Fonaton.svg";
import {MdClass} from "react-icons/all";

export default function Recover() {
    const history = useHistory()
    const [email, setEmail] = useState('')
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    function handleLogin() {
        setLoading(true)
        API.post('/auth/recover-password/', {email: email})
            .then(res => {
                setLoading(false)
                setSent(true)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                setSent(true)
                if(err.response) {
                    console.log(err.response)
                }
            })
        console.log(email)
    }
    const isDisabled = (email.length > 0)
    return (
        <div>
            <Navbar hidePhoto={true}/>
            <div className={classes.main}>
                <div className={classes.content}>
                    <div className={classes.login_container}>
                        <img src={fonaton} alt="logo"/>
                    </div>
                    {sent   ? <div className={classes.container_recover}>
                                <p>
                                    Si ha suministrado su dirección correcta, se le debería haber enviado un email.
                                    Este email tiene instrucciones sencillas para confirmar y completar el cambio de contraseña.
                                </p>
                                <p>
                                    Si sigue teniendo dificultades, contacte por favor a <strong>fonaton.uam@anahuac.mx</strong>
                                </p>
                                <button className={classes.button_submit} onClick={() => history.push('/login')}>
                                    Continuar
                                </button>
                            </div>
                            :
                            <div className={classes.container_recover}>
                                <p>
                                    Para recuperar su contraseña, escriba su dirección de correo electrónico.
                                    Le enviaremos un email con instrucciones para poder acceder de nuevo.
                                </p>
                                <div className={classes.form_group} style={{width:"100%"}}>
                                    <label style={{textAlign:"center"}} className={classes.label_form} htmlFor="email">Escribe tu correo</label>
                                    <input name="email" type="email" onChange={(e) => setEmail(e.target.value)}/>
                                </div>
                                {loading ? <div><Loader /></div> :<button className={classes.button_standar} onClick={handleLogin} disabled={!isDisabled}>Enviar correo</button>}
                            </div>
                    }
                </div>
            </div>
            <Footer />
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import jwtDecode from 'jwt-decode'

import Pager from '../../UI/Pager/Pager'
import Loader from '../../UI/FullScreenLoader/FullscreenLoader'
import API from '../../API'
import Table from './DonationsTable'
import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import Sidebar from '../../UI/Sidebar/Sidebar'
import { useHistory } from 'react-router-dom'
import classes from './Perfil.module.css'
const MONTHS = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio","Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"]
export default function Facturacion(props) {
    const history = useHistory()
    const [donations, setDonations] = useState([])
    const [recurrentActive, setRecurrentActive] = useState([])
    const [phone, setPhone] = useState(null)
    const [pagination, setPagination] = useState({links: {prev: null,next: null}})
    const [lastDonation, setLastDonation] = useState({
        id: '-',
        donative_request: {
            id: '-',
            amount: '-',
            frequency: {
                name: '-',
                updated_at: '-',
                months: '-'
            }
        }
    })
    const [loading, setLoading] = useState(true)
    const [user, setUser] = useState({})
    useEffect(() => {
        let authUseEffect
        let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }
        API.get(`/donatives/?benefactor=${decoded.user_id}`, {
            headers: authUseEffect
        })
            .then(res => {
                console.log(res)
                setDonations(res.data.data)
                setPagination(res.data.pagination)
                setLoading(false)

            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                if (err.response) {
                    console.log(err.response)
                }
            })
    }, [])
    useEffect(() => {
        let authUseEffect
        let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }
        API.get(`/donatives/?benefactor=${decoded.user_id}&type=2`, {
            headers: authUseEffect
        })
            .then(res => {
                if (res.data.data.length > 0 && res.data.data[0].donative_request.canceled === false) setRecurrentActive(res.data.data)
                if (res.data.data.length > 0 && res.data.data[0].donative_request.canceled === false) setLastDonation(res.data.data[0])
                // setLastDonation(res.data.data[0])

            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                if (err.response) {
                    console.log(err.response)
                }
            })
    }, [])
    useEffect(() => {
        let authUseEffect
        let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }

        API.get(`/donatives/benefactors/${decoded.user_id}/`, {
            headers: authUseEffect
        })
            .then(res => {
                setPhone(res.data.phone)
                console.log('datos')
                console.log(res.data)
                setUser(res.data)
            })
            .catch(err => {

            })
    }, [])
    function handleCancelDonation(id) {
        setLoading(true)
        let authUseEffect
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
        }
        API.delete(`/donatives/requests/${id}/cancel-request/`, {
            headers: authUseEffect
        }).then(res => {
                // console.log(res)
                window.location.reload(false)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                if (err.response) console.log(err.response)
            })
    }
    function handlePagination(link) {
        setLoading(true)
        let authUseEffect
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
        }
        axios.get(link, {
            headers: authUseEffect
        }).then(res => {
                setDonations(res.data.data)
                setPagination(res.data.pagination)
                setLoading(false)
            })
    }
    function cleanupDate(date) {
        if (date === '-' || date === undefined) return '-'
        const newDate = new Date(date)
        return `${('0' + newDate.getDate()).slice(-2)}-${('0' + (newDate.getMonth() + 1)).slice(-2)}-${newDate.getFullYear()}`
    }
    function getNewDate(date, months) {
        if (date === '-' || date === undefined) return '-'
        const currDate = new Date(date)
        const newDate = new Date(currDate.setMonth(currDate.getMonth() + months))
        return `${newDate.getDate()} de ${MONTHS[newDate.getMonth()]}, ${newDate.getFullYear()}`
    }

    const ModalAlert = ({title, message, route }) => {
        return (
            <div className={classes.modal_container}>
                <div className={user.first_name ? classes.modal_content : classes.d_none}>
                    <h2>{title}</h2>
                    <p>
                        {message}
                    </p>
                    <button
                        className={classes.button_alert}
                        onClick={() => history
                            .push(route)}>
                        Completar registro
                    </button>
                </div>
            </div>
        )
    }
    return (
        <div>
            <div>
                <Loader visible={loading} />
                <Navbar isLoggedIn />
                <div className={classes.main}>
                    <div className={classes.content} >
                        <div className={classes.container}>
                            <h1>Detalle de donativo</h1>
                            <div className={classes.details_content}>
                                <h2 style={{fontWeight:"700", margin:"0", color:"#175e6e"}}>{lastDonation.donative_request.id}</h2>
                                <label>{recurrentActive.length > 0 ?'Donación recurrente activa:' : 'Sin donación recurrente activa'}</label>
                                <span className={classes.span__facturacion}> ${lastDonation.donative_request.amount} / {lastDonation.donative_request.frequency.name}</span>
                                <label>Fecha de facturación:</label>
                                <span className={classes.span__facturacion}>{cleanupDate(lastDonation.donative_request.updated_at)}</span>
                                <label>Próxima fecha de facturación:</label>
                                <span className={classes.span__facturacion}>{getNewDate(lastDonation.donative_request.updated_at, lastDonation.donative_request.frequency.months)}</span>
                            </div>
                            {!+lastDonation.donative_request.id > 0 ? null :
                                <button
                                    className={classes.button_standar}
                                    onClick={() => handleCancelDonation(lastDonation.donative_request.id)}>
                                    Cancelar donativo recurrente
                                </button>}
                            <div>
                                <h3 style={{color:"#000"}}>Historial de donativos</h3>
                                {/*donations.length > 0 ? <Table donations={donations} /> : 'Sin donaciones'*/
                                    <Table donations={donations} />
                                }
                                <Pager
                                    pagination={pagination}
                                    prev={() => handlePagination(pagination.links.prev)}
                                    next={() => handlePagination(pagination.links.next)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {!phone || phone === '' ?
                <ModalAlert
                    title="Termina tu registro."
                    message="Termina de llenar los siguientes campos para iniciar a donar."
                    route="/perfil/detalle"
                />
                : null}
            <Footer />
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'

import Loader from '../../UI/FullScreenLoader/FullscreenLoader'
import API from '../../API'
import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import Sidebar from '../../UI/Sidebar/Sidebar'
import classes from './Perfil.module.css'
import { useHistory } from 'react-router-dom'

export default function Home() {

    let history = useHistory()
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        let authUseEffect
        let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = {Authorization: 'Bearer ' + localStorage.getItem('access')}
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = {Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }
        API.get(`/donatives/benefactors/${decoded.user_id}/`, {
            headers: authUseEffect
        })
            .then(res => {
                setLoading(false)
                console.log(res)
                setUser(res.data)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
            })
    }, [])
    console.log(user)
    const ModalAlert = ({title, message, route }) => {
        return (

            <div className={classes.modal_container}>
                <div className={user.first_name ? classes.modal_content :  classes.d_none}>
                    <h2>{title}</h2>
                    <p>
                        {message}
                    </p>
                    <button
                        className={classes.button_alert}
                        onClick={() => history
                            .push(route)}>
                        Completar registro
                    </button>
                </div>
            </div>
        )
    }
    return (
        <>
            <Loader visible={loading}/>
            <Navbar isLoggedIn/>
            <div className={classes.main}>
                <div className={classes.content}>
                    <div className={classes.welcome}>
                        <h1>¡Hola, {user.first_name}!</h1>
                        <h2>Bienvenido a tu <br/>administrador Fonatón!</h2>
                        <button
                            className={classes.button_standar}
                            onClick={() => history.push('/perfil/donar')}>
                        <span style={{ fontSize: '1.05em'}}>¡Quiero donar!</span>
                        </button>
                    </div>
                    <div className={classes.banner_content}>

                        <div className={[classes.banner_elements,classes.user_details__bg].join(" ")}>
                            <h3>Modificar información personal</h3>
                            <p>
                                Actualiza tu información personal e información fiscal.
                            </p>
                            <button
                                className={[classes.button_standar].join(' ')}
                                onClick={() => history.push('/perfil/detalle')}>
                                Ver más
                            </button>
                        </div>
                        <div className={[classes.banner_elements,classes.payment_methond__bg].join(" ")}>
                            <h3>Métodos de pago</h3>
                            <p>
                                Agrega tus métodos de pago y administra
                                tus donativos.
                            </p>
                            <button
                                className={[classes.button_standar].join(' ')}
                                onClick={() => history.push('/perfil/pago')}>
                                Ver más
                            </button>
                        </div>
                        <div className={[classes.banner_elements,classes.facturacion__bg].join(" ")}>
                            <h3>Ver facturación</h3>
                            <p>
                                Consulta el estado de tus donaciones y sus facturas.
                            </p>
                            <button
                                className={[classes.button_standar].join(' ')}
                                onClick={() => history.push('/perfil/facturacion')}>
                                Ver más
                            </button>
                        </div>

                    </div>
                </div>
                {user.fiscalinformation?.uso_cfdi == null || user.fiscalinformation?.uso_cfdi === '' || user.fiscalinformation?.regimen == null || user.fiscalinformation?.regimen == '' ?
                    <div className={classes.alert}>
                        <p>
                            En caso de requerir la factura de tu donación, deberás dar de alta tus datos fiscales en el apartado de <span onClick={() => history.push('/perfil/detalle')}>Detalles de la cuenta.</span>
                        </p>
                    </div>
                    : null}

                {!user.phone || user.phone === '' ?
                    <ModalAlert
                        title="Termina tu registro."
                        message="Termina de llenar los siguientes campos para iniciar a donar."
                        route="/perfil/detalle"
                    />
                    : null}
            </div>

            <Footer />

        </>
    )
}
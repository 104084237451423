import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { withStyles } from '@material-ui/core';

function CircularLoader(props) {
    const { classes } = props;
    return (
        <CircularProgress {...props} classes={{colorPrimary: classes.colorPrimary, svg:classes.svg}}/>
    )
}

const styles = props => ({
    colorPrimary: {
        backgroundColor: 'transparent',
    },
    svg: {
        color: '#ff5900'
    }
})

export default withStyles(styles)(CircularLoader)
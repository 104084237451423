import React, { useState } from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { GoogleLogin } from 'react-google-login'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {FaArrowRight, FaEye, FaFacebookF, FaGoogle} from 'react-icons/fa'
import { Link, useHistory } from 'react-router-dom'

import API from '../../API'
import Loader from '../../UI/CircularLoader/CircularLoader'
import Footer from '../../UI/Footer/Footer'
import logo from '../../UI/Assets/Fonaton.svg'
import classes from './Login.module.css'
import banner from "../../UI/Assets/register_banner.png";

export default function Registro() {

    const history = useHistory()

    const isLoggedIn = sessionStorage.getItem('isAuth') || localStorage.getItem('isAuth')

    const [loading, setLoading] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [snackContent, setSnackContent] = useState('')
    const [showPassword, setShowPassword] = useState(false)

    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [privacy, setPrivacy] = useState(false)
    const [legal, setLegal] = useState(false)

    function handleRegister() {
        setLoading(true)

        const data = new FormData()
        data.set('first_name', name)
        data.set('last_name', lastName)
        data.set('email', email)
        data.set('password', password)

        API.post('/donatives/benefactors/register_benefactor/', data)
            .then(res => {
                console.log(res)
                setLoading(false)
                history.push(`/confirmacion-registro?email=${email}`)
            })
            .catch(err => {
                setLoading(false)
                if(err.response) {
                    console.log(err.response)
                    setSnackContent(err.response.data.errors[0])
                    setOpenSnack(true)
                } else {
                    setSnackContent('Error de conexión.')
                    setOpenSnack(true)
                }
            })
    }

    function responseGoogle(e) {
        const data = {
            provider_name: 'google',
            provider_id: e.profileObj.googleId,
            email: e.profileObj.email,
            first_name: e.profileObj.givenName,
            last_name: e.profileObj.familyName,
        }
        console.log('DATA')
        console.log(data)

        API.post('/donatives/benefactors/social_network_access_benefactor/', data)
            .then(res => {
                console.log(res)
                localStorage.setItem('isAuth', 'true')
                localStorage.setItem('access', res.data.access)
                localStorage.setItem('refresh', res.data.refresh)
                localStorage.setItem('remember', 'true')
                history.push('/perfil')
            })
            .catch(err => {
                // console.log(err)
                setSnackContent('Hubo un error a la hora de registrarlo, intente de nuevo.')
                setOpenSnack(true)
            })
    }

    function failureGoogle(e) {
        setSnackContent('Problema al intentar establecer comunicación con Google, intente de nuevo más tarde.')
        setOpenSnack(true)
        // console.log('ERROR GOOGLE')
        // console.log(e)
    }

    function responseFacebook(e) {
        const data = {
            provider_name: 'facebook',
            provider_id: e.userID,
            email: e.email,
            first_name: e.name.split(' ')[0],
            last_name: e.name.split(' ')[1],
        }

        API.post('/donatives/benefactors/social_network_access_benefactor/', data)
            .then(res => {
                console.log(res)
                localStorage.setItem('isAuth', 'true')
                localStorage.setItem('access', res.data.access)
                localStorage.setItem('refresh', res.data.refresh)
                localStorage.setItem('remember', 'true')
                history.push('/perfil')
            })
            .catch(err => {
                setSnackContent('Hubo un error a la hora de registrarlo, intente de nuevo.')
                setOpenSnack(true)
            })
    }

    const isEnabled = (
        privacy &&
        legal &&
        name.length > 0 &&
        lastName.length > 0 &&
        email.length > 0 &&
        password.length > 0
    )

    if(isLoggedIn) {
        history.push('/')
    }

    return(
        <div>
            <Snackbar 
                anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
                open={openSnack}
                autoHideDuration={5000}
                onClose={() => setOpenSnack(false)}
                message="Usuario Creado">
                <SnackbarContent
                    style={{ backgroundColor: '#f43f5e', }}
                    message={
                        <span style={{ fontWeight: '700', color: '#4c0519', }}>
                            {snackContent}
                        </span>
                    }/>
            </Snackbar>
            <div>
                <div className={classes.login_container}>
                    <img src={logo} alt="logo" className={classes.logo} />
                </div>
                <div className={classes.main_container}>
                    <form>
                        <div>
                            <h1>Regístro</h1>
                            <p>Se parte del cambio</p>
                        </div>
                        <div className={classes.form_group}>
                            <label className={classes.label_form} htmlFor="name">Nombre(s)</label>
                            <input placeholder="Escribe tu nombre" type="text" onChange={(e) => setName(e.target.value)} name="name" type="text"/>
                        </div>
                        <div className={classes.form_group}>
                            <label className={classes.label_form} htmlFor="last">Apellidos</label>
                            <input placeholder="Escribe tus apellidos" type="text" onChange={(e) => setLastName(e.target.value)} name="last" type="text"/>
                        </div>
                        <div className={classes.form_group}>
                            <label className={classes.label_form} htmlFor="email">Correo electrónico</label>
                            <input placeholder="Escribe tu correo" type="email" onChange={(e) => setEmail(e.target.value)} name="email" type="email"/>
                        </div>
                        <div className={classes.form_group}>
                            <label className={classes.label_form} htmlFor="password">Contraseña</label>
                            <input onChange={(e) => setPassword(e.target.value)} name="password" type={showPassword ? "text" : "password"}/>
                            <i className={classes.password_function} onClick={()=> setShowPassword(!showPassword)}>
                                <FaEye />
                            </i>
                        </div>
                        <div className={classes.form_group}>
                            <label className="b-contain">
                                <span className={classes.text}>
                                    Acepto el <a className={classes.text} href="/privacidad-fonaton" target="_blank" rel="noopener noreferrer">Aviso de Privacidad para Benefactores.</a>
                                </span>
                                <input type="checkbox" checked={privacy} onChange={() => setPrivacy(!privacy)}/>
                                <div className="b-input" style={{margin:'0px'}}></div>
                            </label>
                            <label className="b-contain">
                                <span className={classes.text}>
                                    Acepto el <a className={classes.text} href="https://merida.anahuac.mx/institucional/aviso-de-privacidad" target="_blank" rel="noopener noreferrer">Aviso de Privacidad de la Universidad Anáhuac Mayab</a> y <br/>
                                    <a className={classes.text} href="https://merida.anahuac.mx/institucional/terminos-y-condiciones-de-uso" target="_blank" rel="noopener noreferrer">los términos, políticas y condiciones.</a>
                                </span>
                                <input type="checkbox" checked={legal} onChange={() => setLegal(!legal)}/>
                                <div className="b-input" style={{margin:'0px'}}></div>
                            </label>
                        </div>
                        <div>
                            {/* <div className={classes.fButton}>
                                <span ><FaFacebookF /></span>Regístrate con Facebook
                            </div> */}
                            {
                                /*
                                <FacebookLogin
                                appId={process.env.REACT_APP_FB_APP_ID}
                                autoLoad={false}
                                fields="name, email"
                                callback={responseFacebook}
                                render={renderProps => (
                                    <div
                                        onClick={renderProps.onClick}
                                        className={classes.fButton}
                                        style={{cursor: 'pointer'}}
                                    >
                                        <span ><FaFacebookF /></span>Regístrate con Facebook
                                    </div>
                                )}
                            />
                                */
                            }
                            {/* <div className={classes.gButton} style={{marginTop:'5px'}}>
                                <span><FaGoogle /></span> Regístrate con Google
                            </div> */}
                            {
                                /*
                                <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                render={renderProps => (
                                    <div
                                        onClick={renderProps.onClick}
                                        className={classes.gButton}
                                        style={{marginTop:'5px', cursor:'pointer'}}
                                    >
                                        <span><FaGoogle /></span> Regístrate con Google
                                    </div>
                                )}
                                buttonText="Login"
                                onSuccess={responseGoogle}
                                onFailure={failureGoogle}
                                cookiePolicy={'single_host_origin'}
                            />
                                */
                            }
                        </div>
                        <div className={classes.form_row}>
                            {loading ? <Loader /> :
                                <button
                                    onClick={handleRegister}
                                    className={classes.button_register}
                                    disabled={!isEnabled}>
                                    Crear cuenta <FaArrowRight/>
                                </button>}
                            <Link className={classes.button_standar} to="/login">Inicia sesión</Link>
                        </div>
                    </form>
                    <div className={classes.banner_login}>
                        <img src={banner} alt=""/>
                    </div>
                </div>
            </div>
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

import Loader from '../../UI/FullScreenLoader/FullscreenLoader'
import API from '../../API'
import CC from '../../UI/CreditCard/CreditCard'
import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import Sidebar from '../../UI/Sidebar/Sidebar'
import classes from './Perfil.module.css'

// import visa from '../../UI/Assets/visa.png'
// import chip from '../../UI/Assets/chip.svg'
// import back from '../../UI/Assets/ccBack.png'


export default function Pago(props) {

    const history = useHistory()

    const [cards, setCards] = useState(null)
    const [phone, setPhone] = useState(null)
    const [user, setUser] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        let authUseEffect
        let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }
        API.get(`/donatives/${decoded.user_id}/cards/`, {
            headers: authUseEffect
        })
            .then(res => {
                console.log(res)
                setCards(res.data.data)
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                if (err.response) {
                    console.log(err.response)
                }
                setLoading(false)
            })
    }, [])

    useEffect(() => {
        let authUseEffect
        let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }

        API.get(`/donatives/benefactors/${decoded.user_id}/`, {
            headers: authUseEffect
        })
            .then(res => {
                setPhone(res.data.phone)
                setUser(res.data)
                console.log(res.data.phone)
            })
            .catch(err => {
                console.log(err)
            })
        API.get(`/auth/verify-conekta/${decoded.user_id}`, {
            headers: authUseEffect
        })
            .then(res => {
                console.log(res)
            })
            .catch(err => {
                console.log(err)

        })
    }, [])
    const ModalAlert = ({title, message, route }) => {
        return (
            <div className={classes.modal_container}>
                <div className={user.first_name ? classes.modal_content :  classes.d_none}>
                    <h2>{title}</h2>
                    <p>
                        {message}
                    </p>
                    <button
                        className={classes.button_alert}
                        onClick={() => history
                            .push(route)}>
                        Completar registro
                    </button>
                </div>
            </div>
        )
    }
    // Yeah yeah I know I'm dupliating code, but I'm tired and this thing wasn't even planned correctly so it just seemed quicker to copy-paste
    // I hope this doesn't change in the future otherwise... oh boy
    function delteCard(cardId) {
        let authUseEffect
        let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }
        API.delete(`/donatives/${decoded.user_id}/cards/${cardId}/`, {
            headers: authUseEffect
        })
            .then(res => {
                window.location.reload(false)
                // console.log(res)
            })
            .catch(err => {
                if (err.response) {
                    console.log(err.response)
                }
            })
    }

    function makeCardActive(cardId) {
        let authUseEffect
        let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }
        API.patch(`/donatives/${decoded.user_id}/cards/${cardId}/`, { is_principal: true }, {
            headers: authUseEffect
        })
            .then(res => {
                window.location.reload(false)
            })
            .catch(err => {
                if (err.response) {
                    console.log(err.response)
                }
            })
    }

    return (
        <div>
            <Loader visible={loading} />
            <Navbar isLoggedIn />
            <div className={classes.main}>
                <div className={classes.content}>
                    <div className={classes.container} >
                        <h1>Métodos de pago</h1>
                        <span
                            onClick={!phone || phone === '' ? null : () => history.push('/perfil/metodo-pago')}
                            style={!phone || phone === '' ? { cursor: 'not-allowed'} : { cursor: 'pointer'}} className={!phone || phone === '' ? classes.button_submit : classes.button_standar}>
                                Agregar un nuevo método de pago
                        </span>
                        <div className={classes.metodos_content}>
                            {cards ?
                                cards.map((card, i) => (
                                    <CC
                                        key={card.id}
                                        active={card.is_principal}
                                        name={card.card ? card.card.name : '-'}
                                        number={card.card ? card.card.last4 : '-'}
                                        manage
                                        onDelete={() => delteCard(card.id)}
                                        onMakeActive={() => makeCardActive(card.id)}/>
                                )): <span style={{fontWeight:'600'}}>No hay métodos de pago registrados</span>
                            }
                        </div>
                    </div>

                </div>
                {!phone || phone === '' ?
                    <ModalAlert
                        title="Termina tu registro."
                        message="Termina de llenar los siguientes campos para iniciar a donar."
                        route="/perfil/detalle"
                    />
                    : null}
            </div>
            <Footer />
        </div>
    )
}
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import jwtDecode from 'jwt-decode'
import NumberFormat from 'react-number-format'

import API from '../../../API'
import Loader from '../../../UI/FullScreenLoader/FullscreenLoader'
import OrderSummary from '../../Donacion/OrderSummary'
import Card from '../../../UI/CreditCard/CreditCard'
import Navbar from '../../../UI/Navbar/Navbar'
import Footer from '../../../UI/Footer/Footer'
import classes from '../../Donacion/Donacion.module.css'

import donacion from '../../../UI/Assets/Group15.svg'
import person from '../../../UI/Assets/Group13.svg'
import creditCardsColor from '../../../UI/Assets/credit_cards_color.svg'
import crediCradsGray from '../../../UI/Assets/credit_cards_gray.svg'
import paypalColor from '../../../UI/Assets/paypal_color.svg'
import paypalGray from '../../../UI/Assets/paypal_gray.svg'
import verifiedLogo from '../../../UI/Assets/verisign.svg'
import pago from '../../../UI/Assets/Group14.svg'

export default function PerfilDonar() {
    const [dclicked, setDclicked] = useState(false)
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [snackContent, setSnackContent] = useState('')
    const [creditCard, setCreditCard] = useState({})
    const [runningRecurrent, setRunningRecurrent] = useState(true)
    const [donationType, setDonationType] = useState('UNICA')
    const [customAmount, setCustomAmount] = useState(null)
    const [recurrmentPlan, setRecurrmentPlan] = useState(null)
    const [donationAmount, setDonationAmount] = useState(null)
    const [amountId, setAmountId] = useState(null)
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [paymentId, setPaymentId] = useState(null)
    const [billing, setBilling] = useState(false)
    const [userInfo, setUserInfo] = useState({})
    const [principalCauseId, setPrincipalCauseId] = useState([])
    const [principalCauseName, setPrincipalCauseName] = useState('Sin definir')
    const [principalCauseFreq, setPrincipalCauseFreq] = useState([])
    const [principalCauseAmounts, setPrincipalCauseAmounts] = useState([])
    const [principalCausePayment, setPrincipalCausePayment] = useState([])

    const isEnabled = (paymentMethod === 'Tarjeta' && creditCard.is_principal && donationAmount > 0 ? false : (paymentMethod === 'Paypal' && donationAmount > 0 ? false : true))
    //(paymentMethod === 'Paypal' || paymentMethod === 'Tarjeta' && donationAmount > 0)
    //&& (donationType === 'UNICA' || (donationType === 'RECURRENTE' && recurrmentPlan))
    //&& (paymentMethod === 'Tarjeta' && creditCard.is_principal) ? true : false)
    useEffect(() => {
        API.get('/donative-causes/get_is_principal/')
            .then(res => {
                // console.log(res)
                setPrincipalCauseName(res.data.name)
                setPrincipalCauseFreq(res.data.donative_frequencies)
                setPrincipalCauseAmounts(res.data.donative_mounts)
                setPrincipalCausePayment(res.data.payment_sources)
                setPrincipalCauseId(res.data.id)
            })
            .catch(err => {
                console.log(err)
                if (err.response) {
                    console.log(err.response)
                }
            })
    }, [])
    useEffect(() => {
        let authUseEffect
        let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }
        API.get(`/donatives/${decoded.user_id}/cards/get_is_principal/`, {
            headers: authUseEffect
        })
            .then(res => {
                console.log(res)
                setCreditCard(res.data)
            })
            .catch(err => {
                console.log(err)
                if (err.response) console.log(err.response)

            })

    }, [])
    useEffect(() => {
        let authUseEffect
        let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }
        API.get(`/donatives/?benefactor=${decoded.user_id}&type=2`, {
            headers: authUseEffect
        })
            .then(res => {
                const Isrecurrent = res.data.data[0]?.donative_request.canceled === true
                if (res.data.data.length === 0 || Isrecurrent) setRunningRecurrent(false)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                if (err.response) {
                    console.log(err.response)
                }
            })
    }, [])
    useEffect(() => {
        let authUseEffect
        let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }

        API.get(`/donatives/benefactors/${decoded.user_id}/`, {
            headers: authUseEffect
        })
            .then(res => {
                console.log(res)
                setUserInfo(res.data)
                setBilling(res.data.fiscalinformation.want_billing)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])
    console.log(userInfo)
    const enableBilling = (
        userInfo.first_name?.length > 0 &&
        userInfo.last_name?.length > 0 &&
        userInfo.fiscalinformation?.rfc?.length > 2 &&
        userInfo.fiscalinformation?.company_name?.length > 0 &&
        userInfo.fiscalinformation?.business_name?.length > 2 &&
        userInfo.address?.zip_code?.length > 0 &&
        userInfo.phone?.length >= 3 &&
        userInfo.email?.length > 2
    )
    let paymentPlans
    if (donationType === 'RECURRENTE') {
        if (runningRecurrent) {
            paymentPlans = (
                <div className={classes.sectionSubtitle} style={{textTransform:"uppercase"}}>
                    No puedes tener más de una donación recurrente activa al mismo tiempo
                </div>
            )
        } else {
            paymentPlans = (
                <div className={classes.flexContainer} onChange={(e) => setRecurrmentPlan(e.target.value)}>
                    {
                        principalCauseFreq.map(freq => (
                            <div key={freq.id} style={{margin:"15px 0"}}>
                                <label className="b-contain" >
                                    <span style={{textTransform:"uppercase"}} className={classes.sectionTitle}>{freq.name}</span>
                                    <input type="radio" name="recurrmentPlan" value={freq.id} />
                                    <div className="b-input" style={{ padding: '8px' }} ></div>
                                </label>
                            </div>))
                    }
                </div>
            )
        }
    }
    let paymentMethodForm
    if (paymentMethod === 'Tarjeta') {
        paymentMethodForm = (
            <div style={{display:"flex",flexDirection:"column",gap:"10px"}}>
                <Card
                    active={creditCard.card ? true : false}
                    name={creditCard.card ? creditCard.card.name : '-'}
                    number={creditCard.card ? creditCard.card.last4 : '-'}
                />
                <span className={classes.button_standar} onClick={() => history.push('/perfil/pago')}>
                    Agregar o cambiar método de pago
                </span>
            </div>
        )
    }
    else if (paymentMethod === 'Paypal') {
        paymentMethodForm = (
            <h4 className={classes.sectionSubtitle}>SERA REDIRECCIONADO A LA PAGINA DE PAYPAL</h4>
        )
    }
    function handleCompleteDonation(e) {
        e.preventDefault()
        setLoading(true)
        let auth
        if (localStorage.getItem('remember') === 'true') {
            auth = { Authorization: 'Bearer ' + localStorage.getItem('access') }
        } else {
            auth = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
        }
        const data = {
            card: {
                token_id: creditCard.token_id
            },
            type: donationType === 'RECURRENTE' ? 2 : 1, // 1 - unique ; 2 - recurrent
            interval: 3, // always 3
            amount: donationAmount,
            payment_source: paymentId,
            donative_cause: principalCauseId,
            fiscalinformation: {
                want_billing: false,
            }
        }
        if (donationType === 'RECURRENTE') {
            data.frequency = +recurrmentPlan
            data.mount = amountId
        }
        if (billing) {
            data.fiscalinformation = {
                company_name: userInfo.fiscalinformation.company_name,
                rfc: userInfo.fiscalinformation.rfc,
                want_billing: billing,
                /*want_billing: true,*/
                business_name: userInfo.fiscalinformation.business_name
            }
        }
        API.post('/donatives/requests/', data, {
            headers: auth
        })
            .then(res => {
                if (res.data.error !== null) {
                    setLoading(false)
                    setSnackContent(res.data.error)
                    setOpenSnack(true)
                } else {
                    window.location = res.data.confirmation_page
                    console.log(res.data)
                    setLoading(false)
                }
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                if (err.response) {
                    console.log(err.response)
                }
            })
        console.log(data)
    }
    function handleCompleteDonationPayPal(e) {
        e.preventDefault()
        setLoading(true)
        let auth

        if (localStorage.getItem('remember') === 'true') {
            auth = { Authorization: 'Bearer ' + localStorage.getItem('access') }
        } else {
            auth = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
        }

        const data = {
            type: donationType === 'RECURRENTE' ? 2 : 1, // 1 - unique ; 2 - recurrent
            interval: 3, // always 3
            amount: donationAmount,
            payment_source: paymentId,
            donative_cause: principalCauseId,
            fiscalinformation: {
                want_billing: false,
            }
        }

        if (donationType === 'RECURRENTE') {
            data.frequency = +recurrmentPlan
            data.mount = amountId
        }

        if (billing) {
            data.fiscalinformation = {
                company_name: userInfo.fiscalinformation.company_name,
                rfc: userInfo.fiscalinformation.rfc,
                want_billing: billing,
                business_name: userInfo.fiscalinformation.business_name
            }
        }
        console.log(data)

        API.post('/donatives/requests/', data, {
            headers: auth
        })
            .then(res => {
                console.log(res)
                window.location = res.data.paypal_url
            })
            .catch(err => {
                setLoading(false)
                setOpenSnack(true)
                if (err.response) {
                    setSnackContent(err.response.data.errors[0])
                } else {
                    setSnackContent('Error. Revise su conexión a internet.')
                }
            })
    }
    function handleClickSubmit(e){
        e.preventDefault()
        paymentMethod === 'Tarjeta' ? handleCompleteDonation(e) : handleCompleteDonationPayPal(e)
        setDclicked(true)
    }
    return (
        <div>
            <div style={{padding:"100px 0"}}>
                <Loader visible={loading} />
                <Snackbar
                    anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
                    open={openSnack}
                    autoHideDuration={5000}
                    onClose={() => setOpenSnack(false)}
                    message="Error">
                    <SnackbarContent
                        style={{ backgroundColor: '#f43f5e', }}
                        message={
                            <span style={{ fontWeight: '700', color: '#4c0519', }}>
                            {snackContent}
                        </span>
                        }/>
                </Snackbar>
                <div>
                    <Navbar />
                    <section>
                        <h2 className={classes.sectionTitle}>
                            <div className={classes.container_icon}>
                                <img src={donacion} alt="donacion" className={classes.icon} />DATOS DE DONACIÓN
                            </div>
                        </h2>
                        <div className={classes.donationTypeContainer}>
                            <button
                                className={donationType === 'UNICA' ? classes.buttonSelected : classes.buttonIdle}
                                onClick={() => {
                                    setDonationType('UNICA')
                                    setCustomAmount(0)
                                    setDonationAmount(null)
                                    setRecurrmentPlan(null)
                                }}>
                                Donación Única
                            </button>
                            <button
                                className={donationType === 'RECURRENTE' ? classes.buttonSelected : classes.buttonIdle}
                                onClick={() => {
                                    setDonationType('RECURRENTE')
                                    setCustomAmount(0)
                                    setDonationAmount(null)
                                }}>
                                Donación Recurrente
                            </button>
                        </div>

                        {paymentPlans}

                        <h3 className={classes.sectionSubtitle} >SELECCIONA EL IMPORTE DE TU DONACIÓN</h3>
                        <div className={classes.flexContainer} >
                            {
                                principalCauseAmounts.map(amount => (
                                    <button
                                        key={amount.id}
                                        className={donationAmount === amount.name ? classes.buttonSelected : classes.buttonIdle}
                                        onClick={(e) => {
                                            setDonationAmount(e.target.value)
                                            setAmountId(amount.id)
                                        }}
                                        value={amount.name}>
                                        ${amount.mount}
                                    </button>
                                ))
                            }
                            {
                                donationType === 'UNICA' ?
                                    <NumberFormat
                                        style={{ textAlign: 'center' }}
                                        value={customAmount}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        suffix={'.00'}
                                        className={donationAmount === customAmount ? classes.buttonSelected : classes.buttonIdle}
                                        allowNegative={false}
                                        decimalSeparator={false}
                                        onValueChange={(e) => {
                                            setDonationAmount(e.value)
                                            setCustomAmount(e.value)
                                        }}
                                        placeholder="Otra"
                                    />
                                    : null
                            }
                        </div>
                    </section>
                    <section>
                        <h2 className={classes.sectionTitle}>
                            <div className={classes.container_icon}>
                                <img src={person} alt="factura" className={classes.icon} /> INFORMACIÓN FISCAL
                            </div>
                        </h2>
                        <h3 className={classes.sectionSubtitle}>INFORMACIÓN DE FACTURACIÓN</h3>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label className="b-contain">
                            <span className={classes.text} style={{display: 'inline' }}>
                                Facturar donativo
                            </span>
                                <input
                                    onChange={() => {setBilling(!billing)}}
                                    type="checkbox"
                                    checked={billing}
                                    disabled={!enableBilling}/>
                                <div className="b-input" style={{ margin: '0px' }}></div>
                            </label>
                            {!enableBilling && (
                                    <div className={classes.conditionsNotMet}>
                                        <strong>IMPORTANTE:</strong><br/>
                                        En caso de requerir la factura de tu donación, deberás dar de alta tus datos fiscales en el apartado de Detalles de la cuenta.
                                    </div>
                            )}
                        </div>
                    </section>
                    {/* FORMA DE PAGO */}
                    <section>
                        <h2 className={classes.sectionTitle}>
                            <div className={classes.container_icon}>
                                <img src={pago} alt="pago" className={classes.icon} /> FORMA DE PAGO
                            </div>
                        </h2>
                        <h3 className={classes.sectionSubtitle}>INFORMACIÓN DE PAGO</h3>
                        <div className={classes.flexContainer} >
                            {
                                principalCausePayment.map(payment => (
                                    <div key={payment.id}>
                                        <label className="b-contain" >
                                            <span style={{ color: '#333', fontWeight: 'normal', display: 'inline' }}></span>
                                            <input type="radio"
                                                   value={payment.name}
                                                   checked={paymentMethod === payment.name}
                                                   onChange={(e) => {
                                                       setPaymentMethod(e.target.value)
                                                       setPaymentId(payment.id)
                                                   }}
                                            />
                                            {
                                                payment.name === 'Tarjeta' ?
                                                    <>
                                                        {paymentMethod === 'Tarjeta' ? <img src={creditCardsColor} alt="creditcard" /> : <img src={crediCradsGray} alt="creditcard" />}
                                                    </>
                                                    :
                                                    <>
                                                        {paymentMethod === 'Paypal' ? <img src={paypalColor} alt="paypal" /> : <img src={paypalGray} alt="paypal" />}
                                                    </>
                                            }
                                            <div className="b-input" style={{ padding: '8px' }} ></div>
                                        </label>
                                    </div>
                                ))
                            }
                        </div>
                        {paymentMethodForm}
                        <div style={{ textAlign: 'left' }}><img src={verifiedLogo} alt="verisign" /></div>
                    </section>
                    <section style={{ textAlign: 'left', paddingBottom: '90px' }}>
                        <button
                            disabled={isEnabled || dclicked}
                            style={{ margin: '50px 0px 10px 0px' }}
                            className={classes.submitButton}
                            onClick={(e)=>paymentMethod === 'Tarjeta' ? handleCompleteDonation(e) : handleCompleteDonationPayPal(e)}>
                            <span style={{ fontSize: '1.05em' }}>Completar donativo</span>
                        </button>
                        {
                            paymentMethod === 'Tarjeta' || paymentMethod === 'Paypal' ? null :
                                <div className={classes.conditionsNotMet}>Revise los campos de su información de pago</div>
                        }
                        {
                            donationAmount > 0 ? null :
                                <div className={classes.conditionsNotMet}>Su donativo tiene que ser mayor a $0.00</div>
                        }
                        {
                            donationType === 'UNICA' || (donationType === 'RECURRENTE' && recurrmentPlan) ? null :
                                <div className={classes.conditionsNotMet}>Revise la recurrencia de su pago</div>
                        }
                        {
                            ((paymentMethod === 'Tarjeta' && creditCard.is_principal) || paymentMethod === 'Paypal') ? null :
                                <div className={classes.conditionsNotMet}>Revise su tarjeta de crédito</div>
                        }
                    </section>
                    <OrderSummary
                        amount={donationAmount}
                        donationType={donationType}
                        cause={principalCauseName}
                    />
                </div>
            </div>
            <Footer />
        </div>
    )
}
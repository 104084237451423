import React from 'react'
import { FaRegFilePdf,FaDownload } from 'react-icons/fa'

import classes from './Perfil.module.css'

export default function Donations(props) {

    function cleanupDate(date) {
        const newDate = new Date(date)
        return `${('0' + newDate.getDate()).slice(-2)}-${('0' + (newDate.getMonth() + 1)).slice(-2)}-${newDate.getFullYear()}`
    }

    return (
        <div>
            <table>
                <thead>
                    <tr className={classes.table__row} >
                        <th>FECHA</th>
                        <th>PERIODICIDAD</th>
                        <th>MÉTODO DE PAGO</th>
                        <th>ESTADO</th>
                        <th>TOTAL</th>
                    </tr>
                </thead>
                <tbody>
                    {props.donations.map((don, i) => (
                        <tr key={i} className={classes.table__row}>
                            <td className={classes.table__fcolumn}
                                onClick={!don.donativebilling ? null : () => window.open(don.donativebilling.file)}>
                                {cleanupDate(don.donative_request.created_at)}
                                {!don.donativebilling ? null : <FaDownload  className={classes.icon__table}/>}
                            </td>
                            <td>{don.donative_request.frequency ? don.donative_request.frequency.name : 'Única'}</td>
                            <td>{don.donative_request.payment_source.name}</td>
                            <td>{don.status === 'paid' ? 'Aceptado' : 'Rechazado'}</td>
                            <td>{don.donative_request.amount}</td>
                        </tr>
                    ))}
                    {/*<tr className={classes.table__row} >
                        <td className={classes.table__fcolumn} >10-12-2019 <FaDownload className={classes.icon__table}/></td>
                        <td>Donativo recurrente</td>
                        <td>VISA **** 4543</td>
                        <td>Aceptado</td>
                        <td>$3,000.00</td>
                    </tr>
                    <tr className={classes.table__row} >
                        <td className={classes.table__fcolumn} >10-12-2019</td>
                        <td>Donativo recurrente</td>
                        <td>VISA **** 4543</td>
                        <td>Rechazado</td>
                        <td>$3,000.00</td>
                    </tr>
                    <tr className={classes.table__row} >
                        <td className={classes.table__fcolumn} >10-12-2019 <FaDownload  className={classes.icon__table}/></td>
                        <td>Donativo recurrente</td>
                        <td>VISA **** 4543</td>
                        <td>Aceptado</td>
                        <td>$3,000.00</td>
                    </tr>
                    <tr className={classes.table__row} >
                        <td className={classes.table__fcolumn} >10-12-2019 <FaDownload className={classes.icon__table} /></td>
                        <td>Donativo recurrente</td>
                        <td>VISA **** 4543</td>
                        <td>Aceptado</td>
                        <td>$3,000.00</td>
                    </tr>*/}
                </tbody>
            </table>
        </div>
    )
}
import React from 'react'

import chip from '../../UI/Assets/chip.svg'
import logo from '../../UI/Assets/visa.png'
import classes from './CreditCard.module.css'

export default function CreditCard(props) {

    return (
        <div className={props.active ? classes.cmain : classes.cimain}>
            <img className={classes.logo} src={logo} width="20%" alt="logo"/>
            <img className={classes.chip} alt="chip" src={chip}/>
            <span className={classes.name}>{props.name}</span>
            <span className={classes.number}>**** **** **** {props.number}</span>
            {
                !props.manage ? null :
                <div className={classes.actions_card}>
                    {
                        props.active ? null : <span className={classes.delete} onClick={props.onDelete}>Eliminar</span>
                    }
                    {
                        props.active ? null :
                        <span className={classes.activate} onClick={props.onMakeActive}>Activar</span>
                    }
                </div>
            }
        </div>
    )
}
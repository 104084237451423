import React, {useState} from 'react'
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import classes from './ImageCrop.module.css'

export default function ImageCrop(props) {

    const [crop, setCrop] = useState({
        aspect: 1,
        unit: 'px',
        x: 0,
        y: 0,
        width: 100,
        height: 100
    })
    const [src, setSrc] = useState(null)
    const [croppedImageUrl, setCroppedImageUrl] = useState(null)
    const [imgRef, setImgRef] = useState(null);


    const onImageLoaded = image => {
        setImgRef(image)
    }
    
    const onCropComplete = crop => {
        if (imgRef && crop.width && crop.height) {
            const croppedImageUrl = getCroppedImg(imgRef, crop)
            setCroppedImageUrl(croppedImageUrl)
        }
    }
    
    function dataURLtoFile(dataurl, filename) {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
                
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        let croppedImage = new File([u8arr], filename, {type:mime});
        setCroppedImageUrl(croppedImage)
    }

    function getCroppedImg(image, crop) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
        
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
         )
    
        const reader = new FileReader()
        canvas.toBlob(blob => {
            reader.readAsDataURL(blob)
            reader.onloadend = () => {
                dataURLtoFile(reader.result, 'cropped.jpg')
            }
        })
    }

    const handleFile = e => {
        const fileReader = new FileReader()
        fileReader.onloadend = () => {
            setSrc(fileReader.result)
        }   
        fileReader.readAsDataURL(e.target.files[0])
    }
    return (
        <>
            {
                props.visible ? 
                <div className={classes.main}>
                    <div className={classes.contanier}>
                        <input type="file" accept="image/*" onChange={handleFile} />
                        <div className={classes.content_buttons}>
                            <button className={classes.button_standar} onClick={() => props.save(croppedImageUrl)} disabled={!croppedImageUrl}>Aceptar</button>
                            <button className={classes.button_submit} onClick={() => props.onCancel()}>Cancelar</button>
                        </div>
                    </div>
                    <ReactCrop 
                        src={src}
                        onImageLoaded={onImageLoaded}
                        crop={crop}
                        onChange={c => setCrop(c)}
                        onComplete={onCropComplete}
                        minHeight={100}
                        minWidth={100}
                        style={{width:'50%'}}
                        imageStyle={{width:'100%'}}
                        circularCrop={true}
                    />
                </div>
                : null
            }
        </>
    )
}
import React from 'react'
import { useHistory } from 'react-router-dom'

import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import classes from './Login.module.css'

export default function EmailConfirm(props) {

    const history = useHistory()
    const search = props.location.search

    return(
        <div>
            <Navbar />
            <div className={classes.main}>
                <div className={classes.content}>
                    <p>
                        Hemos enviado un correo electrónico a <strong>{search.split('=')[1]}</strong>
                    </p>
                    <p>
                        En el encontrará instrucciones para activar su cuenta.
                    </p>
                    <button onClick={() => history.push('/login')} className={classes.button_standar}>
                        <span style={{fontSize:'1.05em'}}>Continuar</span>
                    </button>
                </div>
            </div>
            <Footer />
        </div>
    )
}
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { LinearProgress } from '@material-ui/core'

function LinearLoading(props) {
    const { classes } = props;
    return (
        <LinearProgress {...props} classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}}/>
    )
}

const styles = props => ({
    colorPrimary: {
        backgroundColor: '#175e6e',
    },
    barColorPrimary: {
        backgroundColor: '#e1e1e1',
    }
});

export default withStyles(styles)(LinearLoading)

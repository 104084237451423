import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        localStorage.getItem('isAuth') || sessionStorage.getItem('isAuth')
            ? <Component {...props} />
            : <Redirect to='/login' />
    )} />
)

export default PrivateRoute
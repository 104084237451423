import React from 'react'
import classes from './Footer.module.css'
import Logo from '../Assets/anahuac.svg'
export default function Footer() {
    return (
        <footer>
            <div className={classes.textContainer}>
                <p>
                    ® Universidad Anáhuac Mayab | Todos los derechos reservados
                </p>
                <p>
                    Carr. Mérida Progreso Km. 15.5, CP. 97310, Mérida, Yucatán, México.
                </p>
                <p>
                    Copyright 2019, Red de Universidades Anáhuac. Todos los derechos
                    reservados. <a href="/privacidad-fonaton" target="_blank" rel="noopener noreferrer" style={{color:'white', textDecoration:'none'}}>Avisos de privacidad</a>.
                </p>
            </div>
            <div>
                <img className={classes.logo} src={Logo} alt="AnahuacMayab" />
            </div>
        </footer>
    )
}
import React from 'react'

import classes from './Donacion.module.css'

export default function OrderSummary(props) {

    function buildDate(date) {
        return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`
    }

    function buildAmount(amount) {
        if (amount) {
            return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return null
        }
    }

    return (
        <div className={classes.orderSummary}>
            <div className={classes.mainContainer}>
                <h1>Detalles de la donación</h1>
                <hr />
                <div className={classes.text}>Importe: <span className={classes.text}>${buildAmount(props.amount)}.00</span></div>
                <div className={classes.text}>Fecha: <span className={classes.text}>{buildDate(new Date())}</span></div>
                <div className={classes.text}>Tipo de donación: <span className={classes.text} style={{ float: 'right' }}>{props.donationType === 'UNICA' ? 'Única' : 'Recurrente'}</span></div>
                <div className={classes.text}>Causa: <span className={classes.text}>{props.cause}</span></div>
                <hr />
                <div className={classes.text}>Total: <span className={classes.text}>${buildAmount(props.amount)}.00</span></div>
            </div>
        </div>
    )
}
import React from 'react'

import Footer from '../../UI/Footer/Footer'
import Navbar from '../../UI/Navbar/Navbar'
import classes from './Legal.module.css'

export default function PrivacyFonaton() {

    return (
        <div>
            <Navbar />
            <section className={classes.content}>
                <h1>Aviso de Privacidad para Benefactores.</h1>
                <p>
                    El presente documento constituye el Aviso de Privacidad para efectos de lo dispuesto en la Ley
                    Federal de Protección de Datos Personales en Posesión de los Particulares y las disposiciones que
                    emanan de ella o se relacionan con la misma. Este Aviso de Privacidad aplica a la información
                    personal de (aspirantes, alumnos, egresados, personal directivo, personal docente y personal
                    administrativo), recopilada por la INVESTIGACIONES Y ESTUDIOS SUPERIORES S.C., con domicilio en K.M.
                    15.5. Carretera Mérida Progreso Interior Km. 2, Chablekal, Yucatán; en su carácter de responsable¸
                    quien protege y salvaguarda sus datos personales para evitar el daño, pérdida, destrucción, robo,
                    extravío, alteración, así como el tratamiento no autorizado de sus datos personales, siguiendo los
                    principios de licitud, calidad, consentimiento y condiciones del presente Aviso de Privacidad.
                </p>
                <h2>I.- TRATAMIENTO DE LOS DATOS PERSONALES:</h2>
                <p>
                    Debido a la relación que se pretende, es necesario que usted proporcione y comparta con
                    INVESTIGACIONES Y ESTUDIOS SUPERIORES S.C. a través de la utilización del portal en línea
                    www.fonaton.mx, ciertos datos personales que usted posee.
                    <br/>
                    El Responsable tratará datos personales de identificación, contacto, patrimoniales y/o financieros
                    para cumplir con las finalidades señaladas en el presente aviso de privacidad. Le informamos que el
                    tratamiento de dichos datos es necesario para cumplir las obligaciones derivadas de la relación
                    jurídica entre usted e INVESTIGACIONES Y ESTUDIOS SUPERIORES S.C. razón por la cual su
                    consentimiento se encuentra exceptuado.
                    <br/>
                    Dichos datos personales serán incorporados a un fichero de la compañía para la bolsa de trabajo de
                    la Universidad.
                    <br/>
                    En virtud de que usted nos compartirá información respecto de los Datos Personales, con la finalidad
                    de lograr la realización de la prestación de servicios que se pretende; mediante la aceptación del
                    presente Aviso de Privacidad usted acuerda:
                </p>
                <ol type="a">
                    <li>
                        Que la forma, cantidad y naturaleza de la información respecto de los Datos Personales
                        compartida, estará determinada por el respectivo Aviso de privacidad con el que usted cuente, y
                        regulada por la Ley de Federal de Protección de Datos Personales en Posesión de Particulares;
                    </li>
                    <li>
                        Que cuenta con medidas de seguridad administrativas, técnicas y físicas suficientes para
                        proteger sus datos personales contra daño, pérdida, alteración, destrucción o el uso, acceso o
                        tratamiento no autorizados, con el fin de prevenir fugas de información.
                    </li>
                    <li>
                        Que en ningún caso INVESTIGACIONES Y ESTUDIOS SUPERIORES S.C, será responsable por cualquier
                        lesión, daño, perjuicio, pérdida, reclamo o cualquier daño especial, punitivo, indirecto,
                        incidental por negligencia, o ilícito, que resulte por cualquier uso de este sitio o del
                        contenido que aquí se encuentre.
                    </li>
                </ol>
                <h2>
                    II.- FINALIDAD EN EL TRATAMIENTO DE LOS DATOS PERSONALES:
                </h2>
                <p>
                    Por medio de la presente, se hace de su conocimiento que INVESTIGACIONES Y ESTUDIOS SUPERIORES S.C.
                    recabará sus datos personales sin fines de divulgación, y serán tratados únicamente para los
                    siguientes fines:
                </p>
                <h3>
                    Finalidades primarias.
                </h3>
                <p>
                    INVESTIGACIONES Y ESTUDIOS SUPERIORES S.C. tratará sus datos personales para las siguientes
                    finalidades primarias y necesarias:
                </p>
                <ul>
                    <li>La creación de un perfil para la administración de su participación como benefactor.</li>
                    <li>Para la emisión del comprobante fiscal correspondiente.</li>
                    <li>Para enviar informes de la aplicación de sus donativos.</li>
                    <li>Para el cumplimiento de las obligaciones fiscales y administrativas.</li>
                    <li>Para la gestión de cobro del donativo o fondo.</li>
                </ul>
                <p>
                    Le informamos que usted no puede oponerse para que INVESTIGACIONES Y ESTUDIOS SUPERIORES S.C. cese
                    el tratamiento de sus datos para las finalidades primarias y necesarias, anteriormente mencionadas,
                    en virtud de que el tratamiento es necesario para cumplir obligaciones derivadas de la relación
                    entre usted e INVESTIGACIONES Y ESTUDIOS SUPERIORES S.C.
                    <br/>
                    Los datos personales transmitidos serán guardados bajo la más estricta confidencialidad y no se les
                    podrá dar un uso distinto a los antes mencionados, salvo que medie un cambio en este Aviso de
                    Privacidad. Una vez que se cumpla la finalidad del tratamiento de datos personales, éstos serán
                    bloqueados con el único propósito de determinar posibles responsabilidades en relación con su
                    tratamiento, hasta el plazo de prescripción legal o contractual de éstas. Durante dicho periodo, los
                    datos personales no podrán ser objeto de tratamiento y transcurrido éste, se procederá a su
                    cancelación en la base de datos que corresponde.
                    <br/>
                    Hacemos de su conocimiento que sus datos personales serán resguardados bajo estrictas medidas de
                    seguridad administrativas, técnicas y físicas, las cuales han sido implementadas con el objeto de
                    proteger los datos personales compartidos contra daño, pérdida, alteración, destrucción o el uso,
                    acceso o tratamiento no autorizados. Los datos personales son salvaguardados en bases de datos y
                    equipos de cómputo que cuentan con la seguridad necesaria para prevenir fugas de información.
                </p>
                <h2>
                    III.- RECOLECCIÓN DE LOS DATOS PERSONALES:
                </h2>
                <p>
                    Para la recolección de datos personales, seguimos todos los principios que marca la Ley como la
                    licitud, calidad, consentimiento, información, finalidad, lealtad, proporcionalidad y
                    responsabilidad.
                    <br/>
                    Para las finalidades señaladas en el presente aviso de privacidad, recabaremos indirectamente los
                    datos personales que usted nos proporcione, mediante el ingreso de los mismos en el sitio de
                    Internet o Portal en línea: www.fonaton.mx, así como a través de otros medios ópticos, sonoros,
                    visuales, o por cualquier otra tecnología lícita, incluyendo el correo electrónico, que usted
                    utiliza para requerir la prestación de nuestros servicios.
                </p>
                <h2>
                    IV.- TRANSFERENCIA DE LOS DATOS PERSONALES.
                </h2>
                <p>INVESTIGACIONES Y ESTUDIOS SUPERIORES S.C. no podrá compartir y/o transmitir su información y datos
                    personales. Únicamente, podrá compartir su información:</p>
                <ol type="a">
                    <li>Cuando alguna autoridad gubernamental o diverso funcionario gubernamental responsable de hacer
                        cumplir la ley solicite o requiera razonablemente dicha información;
                    </li>
                    <li>Cuando lo exija la ley o en respuesta a algún proceso legal;</li>
                    <li>Cuando sea razonablemente necesario para llevar a cabo una investigación en relación con
                        actividades ilegales sospechosas o reales, y en el supuesto de que vendamos o transfiramos todo
                        o parte de nuestros negocios o activos.
                    </li>
                    <li>
                        Cuando resulte necesario, en razón al cumplimiento de nuestros contratos de servicios, la
                        Empresa podrá transmitir sus datos personales a terceros, tales como a proveedores de bienes o
                        servicios que requieren conocer esta información se incluyen, de forma enunciativa más no
                        limitativa, a Instituciones Financieras, a Prestador de servicios Legales y/o Fiscales, a
                        proveedores de servicio de asistencia al cliente y de soporte proporcionado en tiempo real,
                        marketing, entre otros.
                        Dichos proveedores de servicios están obligados, por contrato, a mantener la confidencialidad de
                        los datos personales conforme al presente Aviso de Privacidad.
                    </li>
                </ol>
                <p>
                    Si usted no acepta que sus datos estén sujetos a transferencia, entonces debe omitir entregar
                    cualquier dato. Si usted no manifiesta su oposición o negativa para que sus datos sean transferidos,
                    se entenderá que ha otorgado su consentimiento para ello.
                </p>
                <h2>V.- ENCARGADO DEL TRATAMIENTO DE LOS DATOS PERSONALES:</h2>
                <p>
                    El Encargado de la Protección de Datos Personales será Juan Carlos Gómez Lagunas con domicilio en
                    Km. 15.5. Carretera Merida Progreso Interior Km. 2, Chablekal, Yucatán; con horarios de atención de
                    8:00 a 15:00 de Lunes a Viernes, correo electrónico juan.gomezla@anahuac.mx
                </p>
                <h2>VI. MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U OPOSICIÓN:</h2>
                <p>
                    Como titular de los datos personales Usted puede ejercer en cualquier momento sus derechos de
                    acceso, rectificación, cancelación y oposición (los “Derechos ARCO”) de su información, mediante una
                    solicitud por escrito dirigido a Juan Carlos Gómez Lagunas o bien a la dirección de correo
                    electrónico juan.gomezla@anahuac.mx; dicha solicitud deberá contener los siguientes requisitos:
                </p>
                <ul>
                    <li>El nombre del titular y domicilio u otro medio para comunicarle la respuesta a su solicitud;
                    </li>
                    <li>Anexar los documentos que acrediten la identidad o, en su caso, la representación legal que
                        ostenten.
                    </li>
                    <li>La descripción clara y precisa de los datos personales respecto de los que se busca ejercer
                        alguno de los derechos antes mencionados, y
                    </li>
                    <li>Cualquier otro elemento o documento que facilite la localización de los datos personales del
                        titular.
                    </li>
                </ul>
                <p>
                    Usted puede solicitar que se cancelen sus datos personales que INVESTIGACIONES Y ESTUDIOS SUPERIORES
                    S.C. conservan en su expediente una vez que haya concluido su relación con la empresa y/o sus
                    filiales o subsidiarias. No obstante, lo anterior, es posible que INVESTIGACIONES Y ESTUDIOS
                    SUPERIORES S.C. se vea obligada conforme a la legislación aplicable a conservar algunos o todos sus
                    datos personales, o bien, cuando los datos estén siendo utilizados en procesos judiciales vinculados
                    a obligaciones fiscales, administrativos, laborales, mercantiles, civiles, o sujetos a investigación
                    y persecución de delitos penales; así como, cuando sea necesario resguardarlos para cumplir con una
                    obligación legalmente adquirida.
                </p>
                <h2>
                    VI.- CAMBIOS AL PRESENTE AVISO DE PRIVACIDAD:
                </h2>
                <p>
                    INVESTIGACIONES Y ESTUDIOS SUPERIORES S.C. le notificará de cualquier cambio o actualizaciones al
                    presente aviso de privacidad a través de la liga <a href="http://fonaton.mx">http://fonaton.mx</a> y posteriormente accediendo a
                    Aviso de Privacidad.
                    <br/>
                    Última fecha de actualización de este Aviso de Privacidad: 28/02/2024.
                    <br/>
                    Por lo que, una vez consultado el presente, usted reconoce que entiende y acepta transmisión de los
                    datos personales bajo los términos y condiciones señalados en el presente Aviso de Privacidad.
                </p>
            </section>
            <Footer />
        </div>
    )
}
import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'

import classes from './Pager.module.css'

export default function Pager(props) {

    return (
        <div className={classes.main}>
            {props.pagination.links.prev ? <span className={classes.page__indicator} onClick={props.prev}><FaChevronLeft /></span> : <span className={classes.page__indicator_disabled}><FaChevronLeft /></span>}
            {props.pagination.current_page}
            {props.pagination.links.next ? <span className={classes.page__indicator} onClick={props.next}><FaChevronRight /></span> : <span className={classes.page__indicator_disabled}><FaChevronRight /></span>}
        </div>
    )
}
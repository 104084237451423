import axios from 'axios'

const API = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
})

API.interceptors.response.use(response => {
    return response
}, error => {
    // if (error.response.status === 401) {
    //     sessionStorage.clear()
    //     localStorage.clear()
    //     window.location = '/login'
    // } 

    return Promise.reject(error);
})

export default API
import React, { useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import Loader from '../../UI/FullScreenLoader/FullscreenLoader'
import API from '../../API'
import Crop from '../../UI/ImageCrop/ImageCrop'
import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import Sidebar from '../../UI/Sidebar/Sidebar'
import placeholder from '../../UI/Assets/user_placeholder.png'
import classes from './Perfil.module.css'
import { Container } from '@material-ui/core'
import { FormControlLabel, Checkbox } from '@material-ui/core'

export default function UserDetail(props) {

    const cfdis = [
        { value: 'G03', label: 'Gastos en general', regimen: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626] },
        { value: 'D04', label: 'Donativos' , regimen: [605, 606, 608, 611, 612, 614, 607, 615, 625] }
    ]
    const [userBasic, setUserBasic] = useState({})
    const [img, setImg] = useState(null)
    const [name, setName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [street, setStreet] = useState('')
    const [externalNumber, setExternalNumber] = useState('')
    const [colony, setColony] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [socialReason, setSocialReason] = useState('')
    const [regimen, setRegimen] = useState('')
    const [rfc, setRfc] = useState('')
    const [cfdi, setCfdi] = useState('')

    const [billing, setBilling] = useState(false)
    const [loading, setLoading] = useState(true)
    const [editing, setEditing] = useState(false)
    const [cropOpen, setCropOpen] = useState(false)

    useEffect(() => {
        let authUseEffect
        let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }

        API.get(`/donatives/benefactors/${decoded.user_id}/`, {
            headers: authUseEffect
        })
            .then(res => {
                console.log('User data')
                console.log(res)
                setUserBasic(res.data)
                setName(res.data.first_name)
                setLastName(res.data.last_name)

                if (res.data.phone) {
                    setPhone(res.data.phone)
                } else {
                    setPhone('')
                }

                if (res.data.address) {
                    setStreet(res.data.address.street)
                    setExternalNumber(res.data.address.external_number)
                    setColony(res.data.address.colony)
                    setState(res.data.address.state)
                    setCountry(res.data.address.country)
                    setZip(res.data.address.zip_code)
                    setCity(res.data.address.city)
                } else {
                    setStreet('-')
                    setExternalNumber('-')
                    setColony('-')
                    setState('-')
                    setCountry('-')
                    setZip('-')
                    setCity('-')
                }

                if (res.data.fiscalinformation) {
                    setBusinessName(res.data.fiscalinformation.business_name)
                    setSocialReason(res.data.fiscalinformation.company_name)
                    setRfc(res.data.fiscalinformation.rfc)
                    setCfdi(res.data.fiscalinformation.uso_cfdi)
                    setBilling(res.data.fiscalinformation.want_billing)
                    setRegimen(res.data.fiscalinformation.regimen)
                } else {
                    setBusinessName('-')
                    setSocialReason('-')
                    setRfc('-')
                    setCfdi('-')
                }
                setLoading(false)

            })
            .catch(err => {
                console.log(err)
                setLoading(false)
            })
    }, [])

    function editProfile() {
        setLoading(true)
        let authUseEffect
        // let decoded
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            // decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = { Authorization: 'Bearer ' + sessionStorage.getItem('access') }
            // decoded = jwtDecode(sessionStorage.getItem('access'))
        }
        const data = {
            first_name: name,
            last_name: lastName,
            phone: phone,
            address: {
                zip_code: zip.length ? zip : '-'
            },
            fiscalinformation: {
                business_name: businessName.length ? businessName : '-',
                want_billing: billing,
                company_name: '-',
                rfc: rfc.length > 0 ? rfc : '',
                uso_cfdi: cfdi.length > 0 ? cfdi : '',
                regimen: regimen
            }
        }
        console.log(data)
        API.patch('/donatives/benefactors/update_profile/', data, {
            headers: authUseEffect
        })
            .then(res => {
                setLoading(false)
                //console.log(res)
                window.location.reload(false)
            })
            .catch(err => {
                if (err.response) {
                    console.log(err.response)
                }
            })
    }
    function handleCropSave(file) {
        setCropOpen(false)
        setImg(file)
    }
    function handleImgSave() {
        // debugger
        console.log(img)
        setLoading(true)
        let authUseEffect = sessionStorage.getItem('access') ? { Authorization: 'Bearer ' + sessionStorage.getItem('access') } : { Authorization: 'Bearer ' + localStorage.getItem('access') }
        let decoded = sessionStorage.getItem('access') ? jwtDecode(sessionStorage.getItem('access')) : jwtDecode(localStorage.getItem('access'))
        if (localStorage.getItem('remember') === 'true') {
            authUseEffect = { Authorization: 'Bearer ' + localStorage.getItem('access') }
            decoded = jwtDecode(localStorage.getItem('access'))
        }
        const data = new FormData()
        data.append('photo', img)
        data.set('id', decoded.user_id)
        API.patch(`/donatives/benefactors/update_photo/`, data, {
            headers: authUseEffect
        })
            .then(res => {
                window.location.reload(false)
                console.log(res)
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                if (err.response) {
                    console.log(err.response)
                }
            })
    }
    const isEnabled = (
        name.length > 0 &&
        lastName.length > 0 &&
        phone !== undefined &&
        phone.length > 3
    )
    const getRegime = (regimen) => {
        console.log(regimen)
        let option = '';
        switch (regimen) {
            case '601' :
                option = 'General de Ley Personas Morales'
                break;
            case '603' :
                option = 'Personas Morales con Fines no Lucrativos'
                break;
            case '605' :
                option = 'Sueldos y Salarios e Ingresos Asimilados a Salarios'
                break;
            case '606' :
                option = 'Arrendamiento'
                break;
            case '607' :
                option = 'Régimen de Enajenación o Adquisición de Bienes'
                break;
            case '608' :
                option = 'Demás ingresos'
                break;
            case '610' :
                option = 'Residentes en el Extranjero sin Establecimiento Permanente en México'
                break;
            case '611' :
                option = 'Ingresos por Dividendos (socios y accionistas)'
                break;
            case '612' :
                option = 'Personas Físicas con Actividades Empresariales y Profesionales'
                break;
            case '614' :
                option = 'Ingresos por intereses'
                break;
            case '615' :
                option = 'Régimen de los ingresos por obtención de premios'
                break;
            case '616' :
                option = 'Sin obligaciones fiscales'
                break;
            case '620' :
                option = 'Sociedades Cooperativas de Producción que optan por diferir sus ingresos'
                break;
            case '621' :
                option = 'Incorporación Fiscal'
                break;
            case '622' :
                option = 'Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras'
                break;
            case '623' :
                option = 'Opcional para Grupos de Sociedades'
                break;
            case '624' :
                option = 'Coordinados'
                break;
            case '625' :
                option = 'Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas'
                break;
            case '626' :
                option = 'Régimen Simplificado de Confianza'
                break;
        }
        return option;
    }
    const getUso = (cfdi) => {
        console.log(cfdi)
        let option = '';
        switch (cfdi) {
            case 'G03' :
                option = 'Gastos en general'
                break;
            case 'D04' :
                option = 'Donativos'
                break;
        }
        return option;
    }

    const typeUppercase = (e) => {
        e.value = e.value.toUpperCase();
    }

    return (
        <div>
            <div>
                <Loader visible={loading} />
                <Crop
                    visible={cropOpen}
                    save={handleCropSave}
                    onCancel={() => setCropOpen(false)}
                />
                <Navbar isLoggedIn />
                <div className={classes.main}>
                    <div className={classes.content}>
                        <div className={classes.container}>
                            <h1>Información de la Cuenta</h1>
                            <div className={classes.profile_content}>
                                <img
                                    src={img ? URL.createObjectURL(img) : (userBasic.photo ? userBasic.photo : placeholder)}
                                    alt="profile"
                                />
                                <button className={classes.button_submit} onClick={() => setCropOpen(true)}>
                                    Subir foto
                                </button>
                                {!img ? null :
                                    <button
                                        onClick={handleImgSave}
                                        className={classes.button_submit}>
                                        Guardar
                                    </button>
                                }
                            </div>
                            <div>
                                <div className={classes.row}>
                                    <div className={classes.details_content}>
                                        <h3>DATOS DEL USUARIO</h3>
                                        <div>
                                            <label>Nombre(s)</label>
                                            <div className={classes.user_data} hidden={editing}>{name}</div>
                                            <input
                                                value={name}
                                                type="text"
                                                onChange={(e) => setName(e.target.value)}
                                                defaultValue={name}
                                                hidden={!editing}
                                            />
                                        </div>
                                        <div>
                                            <label>Apellidos</label>
                                            <div className={classes.user_data} hidden={editing}>{lastName}</div>
                                            <input
                                                value={lastName}
                                                type="text"
                                                onChange={(e) => setLastName(e.target.value)}
                                                defaultValue={lastName}
                                                hidden={!editing}
                                            />
                                        </div>
                                        <div>
                                            <label>Correo Electrónico</label>
                                            <div className={classes.user_data}>{userBasic.email}</div>
                                        </div>
                                        <div>
                                            <label>Teléfono</label>
                                            <div className={classes.user_data} hidden={editing}>{phone}</div>
                                            {!editing ? null :
                                                <PhoneInput
                                                    placeholder="Número de teléfono"
                                                    value={phone}
                                                    onChange={setPhone}
                                                    international
                                                    defaultCountry='MX'
                                                />}
                                        </div>
                                    </div>
                                    <div className={classes.details_content}>
                                        <h3>DATOS DE FACTURACIÓN</h3>
                                        <div>
                                            <label>Razón Social ó Nombre Completo</label>
                                            <div className={classes.user_data}
                                                 hidden={editing}>{businessName === '' || businessName === '-' ? 'Ingrese su nombre completo ó razón social' : businessName}</div>
                                            <input
                                                value={businessName === '-' ? '' : businessName}
                                                type="text"
                                                onChange={(e) => setBusinessName(e.target.value)}
                                                defaultValue='-'
                                                onInput={(e) => typeUppercase(e.target)}
                                                hidden={!editing}
                                            />
                                        </div>
                                        <div>
                                            <label>R.F.C.</label>
                                            <div className={classes.user_data}
                                                 hidden={editing}>{rfc === '-' || rfc === '' ? 'Ingresa tu RFC' : rfc}</div>
                                            <input
                                                value={rfc === '-' ? '' : rfc}
                                                type="text"
                                                onChange={(e) => setRfc(e.target.value)}
                                                defaultValue='-'
                                                hidden={!editing}
                                            />
                                        </div>
                                        <div>
                                            <label>Código Postal</label>
                                            <div className={classes.user_data}
                                                 hidden={editing}>{zip === '-' || zip === ''  ? 'Ingresa tu código postal.' : zip}</div>
                                            <input
                                                value={zip === '-' ? '' : zip}
                                                type="text"
                                                onChange={(e) => setZip(e.target.value)}
                                                defaultValue='-'
                                                hidden={!editing}
                                            />
                                        </div>
                                        <div>
                                            <label style={{display: "block"}}>Regimen Fiscal</label>
                                            <div className={classes.user_data} hidden={editing}>
                                                {regimen === '' || regimen == null ? 'Selecciona un régimen fistal.' : getRegime(regimen)}
                                            </div>
                                            <select value={regimen === '' || regimen == null ? '' : regimen}
                                                    onChange={(e) => setRegimen(e.target.value)}
                                                    hidden={!editing}>
                                                <option value="601">General de Ley Personas Morales</option>
                                                <option value="603">Personas Morales con Fines no Lucrativos</option>
                                                <option value="605">Sueldos y Salarios e Ingresos Asimilados a Salarios
                                                </option>
                                                <option value="606">Arrendamiento</option>
                                                <option value="607">Régimen de Enajenación o Adquisición de Bienes
                                                </option>
                                                <option value="608">Demás ingresos</option>
                                                <option value="610">Residentes en el Extranjero sin Establecimiento
                                                    Permanente en México
                                                </option>
                                                <option value="611">Ingresos por Dividendos (socios y accionistas)
                                                </option>
                                                <option value="612">Personas Físicas con Actividades Empresariales y
                                                    Profesionales
                                                </option>
                                                <option value="614">Ingresos por intereses</option>
                                                <option value="615">Régimen de los ingresos por obtención de premios
                                                </option>
                                                <option value="616">Sin obligaciones fiscales</option>
                                                <option value="620">Sociedades Cooperativas de Producción que optan por
                                                    diferir sus ingresos
                                                </option>
                                                <option value="621">Incorporación Fiscal</option>
                                                <option value="622">Actividades Agrícolas, Ganaderas, Silvícolas y
                                                    Pesqueras
                                                </option>
                                                <option value="623">Opcional para Grupos de Sociedades</option>
                                                <option value="624">Coordinados</option>
                                                <option value="625">Régimen de las Actividades Empresariales con
                                                    ingresos a
                                                    través de Plataformas Tecnológicas
                                                </option>
                                                <option value="626">Régimen Simplificado de Confianza</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label>Uso de CFDI</label>
                                            <div className={classes.user_data}
                                                 hidden={editing}>{cfdi === '' || cfdi === null || cfdi === '-' ? 'Selecciona un uso de CFDI.' : getUso(cfdi)}
                                            </div>
                                            <select value={cfdi === '' || cfdi == null ? '' : cfdi}
                                                    onChange={(e) => setCfdi(e.target.value)}
                                                    hidden={!editing}>
                                                    <option selected hidden>Selecciona un uso de CFDI.</option>
                                                {
                                                    regimen !== "" || regimen !== null ?
                                                        cfdis.map((cfdi, index) => {
                                                            if (cfdi.regimen.includes(parseInt(regimen))) {
                                                                return <option key={index}
                                                                               value={cfdi.value}>{cfdi.label}</option>
                                                            }
                                                        }) : (
                                                            <option value="">Selecciona un uso de CFDI.</option>
                                                        )
                                                }
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div style={{marginTop:"1rem"}}>
                                    <label className="b-contain">
                                                <span className={classes.text} style={{display: 'inline'}}>
                                                    Facturar donativo
                                                </span>
                                        <input
                                            onChange={() => {
                                                setBilling(!billing)
                                            }}
                                            type="checkbox"
                                            checked={billing}
                                            disabled={!editing}/>
                                        <div className="b-input" style={{margin: '0px'}}></div>
                                    </label>
                                </div>
                                <div className={classes.submit_container}>
                                    <button
                                        className={classes.button_submit}
                                        onClick={editing ? editProfile : () => setEditing(true)}
                                        disabled={editing ? !isEnabled : false}>
                                        {editing ? 'Guardar' : 'Editar'}
                                    </button>
                                    {editing ? (
                                        <button
                                            className={classes.button_submit}
                                            onClick={!editing ? editProfile : () => setEditing(false)}>
                                            {editing ? 'Cancelar' : null}
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className={classes.container}>
                            <div className={classes.details_content}>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}
import React from 'react'
import { Route } from 'react-router-dom'
import './App.css';

import PrivateRoute from './PrivateRoute'
import Donacion from './views/Donacion/Donacion'
import Confirmacion from './views/Confirmacion/Confirmacion'
import EmailConfirm from './views/Registro/EmailConfirm'
import Registro from './views/Registro/Registro'
import Login from './views/Registro/Login'
import Recover from './views/Registro/Recover'
import Perfil from './views/Perfil/Home'
import PerfilDonar from './views/Perfil/Donar/PerfilDonar'
import Detalle from './views/Perfil/UserDetail'
import Facturacion from './views/Perfil/Facturacion'
import Pago from './views/Perfil/Pago'
import NewPayment from './views/NewPayment/NewPayment'
import Privacy from './views/Legal/Privacy'
import PrivacyFonaton from './views/Legal/PrivacyFonaton'
import LegalFonaton from './views/Legal/LegalFonaton'

function App() {
	return (
		<div className="App">
			<Route exact path="/" component={Donacion}/>
			<PrivateRoute exact path="/confirmacion-pago" component={Confirmacion} />
			<Route exact path="/confirmacion-registro" component={EmailConfirm} />
			<Route exact path="/registro" component={Registro} />
			<Route exact path="/login" component={Login} />
			<Route exact path="/recuperar" component={Recover} />
			<PrivateRoute exact path="/perfil" component={Perfil} />
			<PrivateRoute exact path="/perfil/donar" component={PerfilDonar} />
			<PrivateRoute exact path="/perfil/detalle" component={Detalle} />
			<PrivateRoute exact path="/perfil/facturacion" component={Facturacion} />
			<PrivateRoute exact path="/perfil/pago" component={Pago} />
			<PrivateRoute exact path="/perfil/metodo-pago" component={NewPayment}/>
			{/*<Route exact path="/privacidad-anahuac" component={Privacy}/>*/}
			<Route exact path="/privacidad-fonaton" component={PrivacyFonaton}/>
			{/*<Route exact path="/legal-fonaton" component={LegalFonaton}/>*/}
		</div>
	);
}

export default App;

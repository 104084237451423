import React, { useState, useEffect } from 'react'
import jwtDecode from 'jwt-decode'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'

import classes from '../Perfil/Perfil.module.css'
import CreditCard from "../../UI/CreditCard/CreditCard";

import { useHistory } from 'react-router-dom'

import API from '../../API'
import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import verifiedLogo from '../../UI/Assets/verisign.svg'

import visa from '../../UI/Assets/visa.png'
import chip from '../../UI/Assets/chip.svg'
import back from '../../UI/Assets/ccBack.png'

import { FaUser, FaCreditCard, FaLock, FaCalendarAlt, FaChevronLeft } from 'react-icons/fa'

export default function NewPayment(props) {
    const history = useHistory()
    const [openSnack, setOpenSnack] = useState(false)
    const [snackContent, setSnackContent] = useState('')
    const [number, setNumber] = useState('')
    const [name, setName] = useState('')
    const [exp_year, setExp_year] = useState('')
    const [exp_month, setExp_month] = useState('')
    const [cvc, setCvc] = useState('')
    useEffect(() => {
        window.Conekta.setPublicKey(process.env.REACT_APP_CONEKCTA_PUBLIC_KEY);
    }, [])
    function tokenizeCard() {
        const tokenParams = {
            card: {
                number: number,
                name: name,
                exp_year: exp_year,
                exp_month: exp_month,
                cvc: cvc,
            }
        }
        // console.log(tokenParams)
        window.Conekta.Token.create(tokenParams, successResponseHandler, errorResponseHandler)
    }

    function successResponseHandler(e) {
        let authUseEffect
        let decoded
        if(localStorage.getItem('remember') === 'true') {
            authUseEffect = {Authorization: 'Bearer ' + localStorage.getItem('access')}
            decoded = jwtDecode(localStorage.getItem('access'))
        } else {
            authUseEffect = {Authorization: 'Bearer ' + sessionStorage.getItem('access')}
            decoded = jwtDecode(sessionStorage.getItem('access'))
        }
        const data = {
            email:decoded.email,
            token_id: e.id
        }
        API.post(`/donatives/${decoded.user_id}/cards/`, data, {
            headers: authUseEffect
        })
        .then(res => {
            history.push('/perfil/pago')
        })
        .catch(err => {
            if(err.response) {
                console.log(err.response)
            }
        })
    }
    function errorResponseHandler(e) {
        // console.log('ERROR')
        // console.log(e)
        setOpenSnack(true)
        setSnackContent(e.message_to_purchaser)
    }

    return(
        <div>
            <Snackbar anchorOrigin={{vertical: 'bottom', horizontal: 'center',}}
                open={openSnack}
                autoHideDuration={7000}
                onClose={() => setOpenSnack(false)}
                message="Error">
                <SnackbarContent
                    style={{ backgroundColor: '#f43f5e', }}
                    message={
                        <span style={{ fontWeight: '700', color: '#4c0519', }}>
                            {snackContent}
                        </span>
                    }/>
            </Snackbar>
            <Navbar isLoggedIn/>
            <div className={classes.main}>
                <div className={classes.content}>
                    <div className={classes.container}>
                        <div className={classes.button_submit} onClick={() => history.push('/perfil/pago')} style={{cursor:'pointer'}}>
                            <FaChevronLeft style={{fontSize:"14px"}}/> Regresar
                        </div>
                        <h1>Agregar nuevo método de pago</h1>
                        <img style={{width:'100px',}} src={verifiedLogo} alt="verisign"/>
                        <CreditCard active={true} name={name} number={number.slice(-4)}/>
                        <div className={classes.details_content}>
                            <div className={classes.content_icon}>
                                <span className={classes.icon_payment}><FaUser/></span>
                                <label>Propietario de la tarjeta</label>
                                <input
                                    className={classes.input_card}
                                    onChange={(e) => setName(e.target.value)}
                                    type="text"
                                    placeholder="Escribe el nombre del titular"
                                />
                            </div>
                            <div className={classes.content_icon}>
                                <span className={classes.icon_payment}><FaCreditCard/></span>
                                <label>Número de la tarjeta</label>
                                <input
                                    className={classes.input_card}
                                    onChange={(e) => setNumber(e.target.value)}
                                    type="text"
                                    maxLength="16"
                                    placeholder="Escribe el número de la tarjeta"
                                />
                            </div>
                            <div style={{width:'100%',fontSize:"2em",fontWeight:"700"}}>Fecha de expiración</div>
                            <div className={classes.fecha_expiracion}>
                                <div className={classes.content_icon}>
                                    <span className={classes.icon_payment}><FaCalendarAlt/></span>
                                    <label>Mes</label>
                                    <input
                                        className={classes.input_card}
                                        onChange={(e) => setExp_month(e.target.value)}
                                        type="text"
                                        maxLength="2"
                                        minLength="2"
                                        style={{width:'50px'}}
                                        placeholder="01"
                                    />
                                </div>
                                <div className={classes.content_icon}>
                                    <span className={classes.icon_payment}><FaCalendarAlt/></span>
                                    <label>Año</label>
                                    <input
                                        className={classes.input_card}
                                        onChange={(e) => setExp_year(e.target.value)}
                                        type="text"
                                        maxLength="2"
                                        minLength="2"
                                        style={{width:'50px'}}
                                        placeholder="27"
                                    />
                                </div>
                                <div className={classes.content_icon}>
                                    <span className={classes.icon_payment}><FaLock/></span>
                                    <label>CVV</label>
                                    <input
                                        className={classes.input_card}
                                        onChange={(e) => setCvc(e.target.value)}
                                        type="text"
                                        maxLength="3"
                                        minLength="3"
                                        placeholder="123"
                                        style={{width:'50px'}}
                                    />
                                </div>
                            </div>
                        </div>
                        <button className={classes.button_standar} onClick={tokenizeCard}>
                            Agregar
                        </button>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
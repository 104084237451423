import React, { useState, useEffect } from 'react'
import { Redirect } from 'react-router-dom'
import Snackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import NumberFormat from 'react-number-format'
import PhoneInput from 'react-phone-number-input'
import classes from './Donacion.module.css'
import inputClasses from './Input.module.css'
import './donacion.css'

import Loader from '../../UI/FullScreenLoader/FullscreenLoader'
import API from '../../API'
import OrderSummary from './OrderSummary'
import Navbar from '../../UI/Navbar/Navbar'
import Footer from '../../UI/Footer/Footer'
import verifiedLogo from '../../UI/Assets/verisign.svg'

import creditCardsColor from '../../UI/Assets/credit_cards_color.svg'
import crediCradsGray from '../../UI/Assets/credit_cards_gray.svg'
import paypalColor from '../../UI/Assets/paypal_color.svg'
import paypalGray from '../../UI/Assets/paypal_gray.svg'

import donacion from '../../UI/Assets/Group15.svg'
import usuario from '../../UI/Assets/Group13.svg'
import pago from '../../UI/Assets/Group14.svg'
import { FaUser, FaCreditCard, FaLock, FaCalendarAlt } from 'react-icons/fa'
import { FormControlLabel, Checkbox } from '@material-ui/core'

export default function Donacion() {

    const isLoggedIn = sessionStorage.getItem('isAuth') || localStorage.getItem('isAuth')

    const [loading, setLoading] = useState(false)
    const [openSnack, setOpenSnack] = useState(false)
    const [snackContent, setSnackContent] = useState('')

    const [principalCauseId, setPrincipalCauseId] = useState([])
    const [principalCauseName, setPrincipalCauseName] = useState('Sin definir')
    // const [principalCauseFreq, setPrincipalCauseFreq] = useState([])
    const [principalCauseAmounts, setPrincipalCauseAmounts] = useState([])
    const [principalCausePayment, setPrincipalCausePayment] = useState([])
    useEffect(() => {
        API.get('/donative-causes/get_is_principal/')
            .then(res => {
                console.log(res.data);
                setPrincipalCauseName(res.data.name)
                // setPrincipalCauseFreq(res.data.donative_frequencies)
                setPrincipalCauseAmounts(res.data.donative_mounts)
                setPrincipalCausePayment(res.data.payment_sources)
                setPrincipalCauseId(res.data.id)
            })
            .catch(err => {
                console.log(err)
                if (err.response) {
                    console.log(err.response)
                }
            })
    }, [])

    const [donationType, setDonationType] = useState('UNICA')
    const [customAmount, setCustomAmount] = useState(null)
    const [recurrmentPlan, setRecurrmentPlan] = useState(null)
    const [donationAmount, setDonationAmount] = useState(null)
    // const [amountId, setAmountId] = useState(null)
    const [personType, setPersonType] = useState('FISICA')
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [paymentId, setPaymentId] = useState(null)

    // const history = useHistory()

    // Form data - User
    const [name, setName] = useState('')
    const [lastNameF, setLastNameF] = useState('')
    const [lastNameM, setLastnameM] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [street, setStreet] = useState('')
    const [externalNumber, setExternalNumber] = useState('')
    const [colony, setColony] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [zip, setZip] = useState('')
    const [city, setCity] = useState('')
    const [socialReason, setSocialReason] = useState('-')
    const [RFC, setRFC] = useState('')
    const [billing, setBilling] = useState(false)
    const [businessName, setBusinessName] = useState('')

    const phisycalPersonCondition = (
        name.length > 0 &&
        lastNameM.length > 0 &&
        lastNameF.length > 0 &&
        email.length > 0 &&
        phone !== undefined
    )

    const moralPersonCondition = (
        socialReason.length > 0 &&
        RFC.length > 0 &&
        businessName.length > 0
    )

    const isBillingDisabled = (
        personType === 'MORAL'
    )

    // Form data - Credit card
    const [cardHolder, setCardHolder] = useState('')
    const [cardNumber, setCardNumber] = useState('')
    const [month, setMonth] = useState('')
    const [year, setYear] = useState('')
    const [CVV, setCVV] = useState('')

    // const [newCC, setNewCC] = useState(false)
    // const [newCardHolder, setNewCardHolder] = useState('')
    // const [newCardNumber, setNewCardNumber] = useState('')
    // const [newMonth, setNewMonth] = useState('')
    // const [newYear, setNewYear] = useState('')
    // const [newCVV, setNewCVV] = useState('')

    const paymentCondition = (
        (cardHolder.length > 0 &&
            cardNumber.length >= 15 &&
            month.length > 0 &&
            year.length > 0 &&
            CVV.length >= 3)
        // || 
        // (
        //     (isLoggedIn && !newCC) ||
        //     (newCC && (
        //         (newCardHolder.length > 0 &&
        //         newCardNumber.length === 16 &&
        //         newMonth.length > 0 &&
        //         newYear.length > 0 &&
        //         newCVV.length === 3)
        //     ))
        // )
    )

    // Form data - Privacy policies
    const [privacyFonaton, setPrivacyFonaton] = useState(false)
    const [privacyAnahuac, setPrivacyAnahuac] = useState(false)
    const [terms, setTerms] = useState(false)

    const privacyCondition = (
        privacyFonaton && privacyAnahuac && terms
    )

    const isEnabled = (
        (
            (phisycalPersonCondition && personType === 'FISICA') ||
            (phisycalPersonCondition && moralPersonCondition && personType === 'MORAL')
        ) &&
        (paymentCondition || paymentMethod === 'Paypal') &&
        donationAmount > 0 &&
        privacyCondition &&
        (donationType === 'UNICA' || (donationType === 'RECURRENTE' && recurrmentPlan))
    )
    /*const isEnabled = false;*/
    useEffect(() => {
        window.Conekta.setPublicKey(process.env.REACT_APP_CONEKCTA_PUBLIC_KEY);
    }, [])

    /*
        =================================
        |                               |
        |       HANDLE CC PAYMENT       |
        |                               |
        =================================
    */
    function successResponseHandler(e) {

        // let fullNameArray = nameOrSocialR.trim().split(' ')

        // let namee = ''
        // let firstnameee = ''
        // let lastnamee = ''

        // for (let i = 0; i < fullNameArray.length; i++) {

        //     if (fullNameArray[0]) {
        //         namee = fullNameArray[0]
        //     }
        //     if (fullNameArray[1]) {
        //         firstnameee = fullNameArray[1]
        //     }
        //     if (fullNameArray[2] && i > 1) {
        //         lastnamee = lastnamee + ' ' + fullNameArray[i]
        //     }
        // }

        const data = {
            benefactor_register: {
                first_name: name,
                last_name: lastNameF + ' ' + lastNameM,
                email: email,
                phone: phone
            },
            card: {
                token_id: e.id
            },
            type: 1, // 1 - unique ; 2 - recurrent
            interval: 3, // always 3
            amount: donationAmount,
            payment_source: paymentId,
            donative_cause: principalCauseId,
            fiscalinformation: {
                want_billing: false,
            }
        }

        if (personType === 'MORAL') data.address = {
            country: country,
            state: state,
            city: city,
            zip_code: zip,
            street: street,
            external_number: externalNumber,
            colony: colony
        }

        if (personType === 'MORAL') data.fiscalinformation = {
            company_name: `${businessName}`,
            rfc: RFC,
            want_billing: billing,
            business_name: businessName
        }

        console.log(data, e)

        API.post('/donatives/requests/', data)
            .then(res => {
                console.log(res)
                if (res.data.error !== null) {
                    setLoading(false)
                    setSnackContent(res.data.error)
                    setOpenSnack(true)
                } else {
                    window.location = res.data.confirmation_page
                    setCustomAmount(null)
                    setRecurrmentPlan(null)
                    setDonationAmount(null)
                    setPersonType(null)
                    setPaymentMethod(null)
                    setPaymentId(null)

                    setName('')
                    setLastNameF('')
                    setLastnameM('')
                    setEmail('')
                    setPhone('')
                    setStreet('')
                    setExternalNumber('')
                    setColony('')
                    setState('')
                    setCountry('')
                    setZip('')
                    setCity('')
                    setSocialReason('')
                    setRFC('')
                    setBilling('')
                    setBusinessName('')
                }
            })
            .catch(err => {
                setLoading(false)
                console.log(err)
                if (err.response) {
                    // console.log(err.response)
                    setSnackContent(err.response.data.errors[0])
                } else {
                    setSnackContent('Error. Revise su conexión a internet.')
                }

                setOpenSnack(true)
            })
    }

    function errorResponseHandler(e) {
        setOpenSnack(true)
        setSnackContent(e.message_to_purchaser)
        setLoading(false)
    }


    function handleCompleteDonation() {
        setLoading(true)
        const tokenParams = {
            card: {
                number: cardNumber,
                name: cardHolder,
                exp_year: year,
                exp_month: month,
                cvc: CVV,
            }
        }
        window.Conekta.Token.create(tokenParams, successResponseHandler, errorResponseHandler)
    }

    /*
        =====================================
        |                                   |
        |       HANDLE PAYPAL PAYMENT       |
        |                                   |
        =====================================
    */
    function handleCompleteDonationPayPal() {

        setLoading(true)

        const data = {
            benefactor_register: {
                first_name: name,
                last_name: lastNameF + ' ' + lastNameM,
                email: email,
                phone: phone
            },
            type: 1, // 1 - unique ; 2 - recurrent
            interval: 3, // always 3
            amount: donationAmount,
            payment_source: paymentId,
            donative_cause: principalCauseId,
            fiscalinformation: {
                want_billing: false,
            }
        }

        if (personType === 'MORAL') data.address = {
            country: country,
            state: state,
            city: city,
            zip_code: zip,
            street: street,
            external_number: externalNumber,
            colony: colony
        }

        if (personType === 'MORAL') data.fiscalinformation = {
            company_name: `${businessName}`,
            rfc: RFC,
            want_billing: billing,
            business_name: businessName
        }

        API.post('/donatives/requests/', data)
            .then(res => {
                console.log(res)
                window.location = res.data.paypal_url
            })
            .catch(err => {
                setLoading(false)
                setOpenSnack(true)
                // console.log(err)
                if (err.response) {
                    // console.log(err.response)
                    setSnackContent(err.response.data.errors[0])
                } else {
                    setSnackContent('Error. Revise su conexión a internet.')
                }
            })
    }

    let paymentPlans = null
    if (donationType === 'RECURRENTE') {
        paymentPlans = (
            <div style={{ marginTop: '10px' }} onChange={(e) => setRecurrmentPlan(e.target.value)}>
                {/* {
                    principalCauseFreq.map(freq => (
                        <div key={freq.id}>
                            <label className="b-contain" >
                                <span style={{color:'#333', fontWeight:'normal', display:'inline'}}>{freq.name}</span>
                                <input type="radio" name="recurrmentPlan" value={freq.months}/>
                                <div className="b-input" style={{padding:'8px'}} ></div>
                            </label>
                        </div>
                    ))
                } */}
                <div className={classes.conditionsNotMet}>Inicie sesión para hacer una donación recurrente</div>
            </div>
        )
    }

    // console.log(name)
    // console.log(lastNameM)
    // console.log(lastNameF)
    // console.log(socialReason)


    // MORAL PERSON FIELDS
    let moralPersonFields = null
    if (personType === 'MORAL') {
        moralPersonFields = (
            <>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column-reverse' }}>
                    <h3>Información de facturación</h3>
                    <hr style={{ width: '29%', position: 'absolute', border: '1px solid #999999', margin: 0 }} />
                </div>
                <div style={{ width: '100%', display: 'flex' }}>
                    <h4 className={classes.dangerText} >* CAMPOS OBLIGATORIOS</h4>
                </div>

                <div className={inputClasses.omrsInputGroup} >
                    <label className={inputClasses.omrsInputUnderlined} >
                        <input
                            onChange={(e) => setBusinessName(e.target.value)}
                            required />
                        <span className={inputClasses.omrsInputLabel} >Razón Social ó Nombre Completo</span>
                    </label>
                </div>

                <div className={inputClasses.omrsInputGroup} >
                    <label className={inputClasses.omrsInputUnderlined} >
                        <input
                            onChange={(e) => setRFC(e.target.value)}
                            required />
                        <span className={inputClasses.omrsInputLabel} >R.F.C *</span>
                    </label>
                </div>

                <div className={inputClasses.omrsInputGroup} >
                    <label className={inputClasses.omrsInputUnderlined} >
                        <input
                            onChange={(e) => setCountry(e.target.value)}
                            required />
                        <span className={inputClasses.omrsInputLabel} >País *</span>
                    </label>
                </div>

                <div className={inputClasses.omrsInputGroup} >
                    <label className={inputClasses.omrsInputUnderlined} >
                        <input
                            onChange={(e) => setState(e.target.value)}
                            required />
                        <span className={inputClasses.omrsInputLabel} >Estado *</span>
                    </label>
                </div>

                <div className={inputClasses.omrsInputGroup} >
                    <label className={inputClasses.omrsInputUnderlined} >
                        <input
                            onChange={(e) => setCity(e.target.value)}
                            required />
                        <span className={inputClasses.omrsInputLabel} >Ciudad *</span>
                    </label>
                </div>

                <div className={inputClasses.omrsInputGroup} >
                    <label className={inputClasses.omrsInputUnderlined} >
                        <input
                            onChange={(e) => setZip(e.target.value)}
                            required />
                        <span className={inputClasses.omrsInputLabel} >Código postal *</span>
                    </label>
                </div>

                <div className={inputClasses.omrsInputGroup} >
                    <label className={inputClasses.omrsInputUnderlined} >
                        <input
                            onChange={(e) => setStreet(e.target.value)}
                            required />
                        <span className={inputClasses.omrsInputLabel} >Calle *</span>
                    </label>
                </div>

                <div className={inputClasses.omrsInputGroup} >
                    <label className={inputClasses.omrsInputUnderlined} >
                        <input
                            onChange={(e) => setExternalNumber(e.target.value)}
                            required />
                        <span className={inputClasses.omrsInputLabel} >Número exterior *</span>
                    </label>
                </div>

                <div className={inputClasses.omrsInputGroup} >
                    <label className={inputClasses.omrsInputUnderlined} >
                        <input
                            onChange={(e) => setColony(e.target.value)}
                            required />
                        <span className={inputClasses.omrsInputLabel} >Colonia *</span>
                    </label>
                </div>
            </>
        )
    }

    // PAYMENT METHOD
    let paymentMethodForm = null
    if (paymentMethod === 'Tarjeta') {
        // if(isLoggedIn) {
        //     paymentMethodForm = (
        //         <>
        //         {
        //             newCC ? null :
        //             <CC 
        //                 number={397}
        //             />
        //         }
        //             <div 
        //                 style={{textAlign:'left', color:'#ff5900', textDecoration:'underline', cursor:'pointer', marginBottom:'2%'}}
        //                 onClick={() => setNewCC(!newCC)}
        //             >
        //                 {
        //                     newCC ? 'Cancelar' : 'Nuevo método de pago'
        //                 }
        //             </div>
        //             {
        //                 !newCC ? null :
        //                 <>
        //                     <div style={{textAlign: 'left'}}>
        //                         <small style={{color:'#ccc'}}>Esta nueva tarjeta se guardará y se pondrá como la principal</small>
        //                         <div  className={inputClasses.omrsInputGroup} >
        //                             <label className={inputClasses.omrsInputUnderlined} >
        //                                 <FaUser className={classes.inputIcon} /> 
        //                                 <input
        //                                     onChange={(e) => setNewCardHolder(e.target.value)} 
        //                                     required />
        //                                 <span className={inputClasses.omrsInputLabel} >Propietaro de la tarjeta</span>
        //                             </label>
        //                         </div>

        //                         <div  className={inputClasses.omrsInputGroup} >
        //                             <label className={inputClasses.omrsInputUnderlined} >
        //                                 <FaCreditCard className={classes.inputIcon} /> 
        //                                 <input
        //                                     onChange={(e) => setNewCardNumber(e.target.value)} 
        //                                     required
        //                                     maxLength="16"
        //                                 />
        //                                 <span className={inputClasses.omrsInputLabel} >Número de tarjeta</span>
        //                             </label>
        //                         </div>

        //                         <h3>Fecha de expiración</h3>
        //                         <div style={{display:'flex'}} className={classes.expirationContainer}>

        //                             <div className={[inputClasses.omrsInputGroup, classes.expiration].join(' ')} >
        //                                 <label className={inputClasses.omrsInputUnderlined} >
        //                                     <FaCalendarAlt className={classes.inputIcon} /> 
        //                                     <input
        //                                         onChange={(e) => setNewMonth(e.target.value)} 
        //                                         maxLength="2" required />
        //                                     <span className={inputClasses.omrsInputLabel} style={{color:'#aaa'}} >Mes</span>
        //                                 </label>
        //                             </div>
        //                             <div className={[inputClasses.omrsInputGroup, classes.expiration].join(' ')} >
        //                                 <label className={inputClasses.omrsInputUnderlined} >
        //                                     <FaCalendarAlt className={classes.inputIcon} /> 
        //                                     <input
        //                                         onChange={(e) => setNewYear(e.target.value)} 
        //                                         maxLength="4" required />
        //                                     <span className={inputClasses.omrsInputLabel} style={{color:'#aaa'}} >Año</span>
        //                                 </label>
        //                             </div>
        //                             <div className={[inputClasses.omrsInputGroup, classes.expiration].join(' ')} >
        //                                 <label className={inputClasses.omrsInputUnderlined} >
        //                                     <FaLock className={classes.inputIcon} /> 
        //                                     <input
        //                                         onChange={(e) => setNewCVV(e.target.value)} 
        //                                         maxLength="3" required />
        //                                     <span className={inputClasses.omrsInputLabel} >CVV</span>
        //                                 </label>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </>
        //             }
        //         </>
        //     )
        paymentMethodForm = (
            <div style={{margin:'1rem 0'}}>
                <div className={classes.formContainer}>
                    <div className={inputClasses.omrsInputGroup} >
                        <label className={inputClasses.omrsInputUnderlined}>
                            <FaUser className={classes.inputIcon}/>
                            <span className={inputClasses.omrsInputLabel}>Titular de la tarjeta</span>
                            <input
                                onChange={(e) => setCardHolder(e.target.value)}
                                required/>
                        </label>
                    </div>
                    <div className={inputClasses.omrsInputGroup}>
                        <label className={inputClasses.omrsInputUnderlined}>
                            <FaCreditCard className={classes.inputIcon}/>
                            <span className={inputClasses.omrsInputLabel}>Número de tarjeta *</span>
                            <input
                                onChange={(e) => setCardNumber(e.target.value)}
                                required
                                maxLength="16"
                            />
                        </label>
                    </div>
                </div>
                <h3 className={classes.sectionSubtitle}>FECHA DE EXPIRACIÓN</h3>
                <div className={classes.formContainer}>
                    <div className={classes.expirationContainer}>

                        <div className={[inputClasses.omrsInputGroup, classes.expiration].join(' ')} >
                            <label className={inputClasses.omrsInputUnderlined}>
                                <span className={inputClasses.omrsInputLabel} style={{color: '#aaa'}}>Mes *</span>
                                <FaCalendarAlt className={classes.inputIcon}/>
                                <input
                                    onChange={(e) => setMonth(e.target.value)}
                                    maxLength="2" required/>
                            </label>
                        </div>
                        <div className={[inputClasses.omrsInputGroup, classes.expiration].join(' ')} >
                            <label className={inputClasses.omrsInputUnderlined}>
                                <span className={inputClasses.omrsInputLabel} style={{color: '#aaa'}}>Año *</span>
                                <FaCalendarAlt className={classes.inputIcon}/>
                                <input
                                    onChange={(e) => setYear(e.target.value)}
                                    maxLength="4" required/>
                            </label>
                        </div>
                    </div>
                    <div className={[inputClasses.omrsInputGroup, classes.expiration].join(' ')} >
                        <label className={inputClasses.omrsInputUnderlined}>
                            <span className={inputClasses.omrsInputLabel}>CVV *</span>
                            <FaLock className={classes.inputIcon}/>
                            <input
                                onChange={(e) => setCVV(e.target.value)}
                                maxLength="4" required/>
                        </label>
                    </div>
                </div>
            </div>
        )
    } else if (paymentMethod === 'Paypal') {
        paymentMethodForm = (
            <h3  className={classes.sectionSubtitle}>SERÁ REDIRECCIONADO A LA PÁGINA DE PAYPALß</h3>
        )
    }

    if (isLoggedIn) return <Redirect to="/perfil/donar" />

    return (
        <div>
            <div style={{padding:'100px 0'}}>

                {/*//Muestra el modal de login*/}
                <Loader visible={loading} />

                {/*//Muestra el modal de errores*/}
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={openSnack}
                    autoHideDuration={5000}
                    onClose={() => setOpenSnack(false)}
                    message="Error"
                >
                    <SnackbarContent
                        style={{ backgroundColor: '#F08080', }}
                        message={
                            <span style={{ fontWeight: '700' }}>
                            {snackContent}
                        </span>
                        }
                    />
                </Snackbar>
                <div>
                    <Navbar />
                    {/* DATOS DE DONACIÓN */}
                    <section>
                        <h2 className={classes.sectionTitle}>
                            <div className={classes.container_icon}>
                                <img src={donacion} alt="donacion"/> DATOS DE DONACIÓN
                            </div>
                        </h2>
                        <div className={classes.donationTypeContainer}>
                            <button
                                className={donationType === 'UNICA' ? classes.buttonSelected : classes.buttonIdle}
                                onClick={() => {
                                    setDonationType('UNICA')
                                    setCustomAmount(0)
                                    setDonationAmount(null)
                                }}>DONACIÓN ÚNICA</button>
                            <button
                                className={donationType === 'RECURRENTE' ? classes.buttonSelected : classes.buttonIdle}
                                onClick={() => {
                                    setDonationType('RECURRENTE')
                                    setCustomAmount(0)
                                    setDonationAmount(null)
                                }}>DONACIÓN RECURRENTE</button>
                        </div>
                        {
                            //Alert if DonationType is Recurrent
                            paymentPlans
                        }
                        <h3 className={classes.sectionSubtitle}>SELECCIONA EL IMPORTE DE TU DONACIÓN</h3>
                        <div className={classes.flexContainer} >
                            {
                                principalCauseAmounts.map(amount => (
                                    <button
                                        key={amount.id}
                                        className={donationAmount === amount.name ? classes.buttonSelected : classes.buttonIdle}
                                        onClick={(e) => {
                                            setDonationAmount(e.target.value)
                                            // setAmountId(amount.id)
                                        }}
                                        value={amount.name}>
                                        ${amount.mount}
                                    </button>
                                ))
                            }
                            {
                                donationType === 'UNICA' ?
                                    <NumberFormat
                                        style={{ textAlign: 'center' }}
                                        value={customAmount}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                        suffix={'.00'}
                                        className={donationAmount === customAmount ? classes.buttonSelected : classes.buttonIdle}
                                        allowNegative={false}
                                        decimalSeparator={false}
                                        onValueChange={(e) => {
                                            setDonationAmount(e.value)
                                            setCustomAmount(e.value)
                                        }}
                                        placeholder="OTRA"
                                    />
                                    : null
                            }
                        </div>
                    </section>

                    {/* INFORMACIÓN PERSONAL */}
                    <section>
                        <h2 className={classes.sectionTitle}>
                            <div className={classes.container_icon}>
                                <img src={usuario} alt="usuario"/> INFORMACIÓN PERSONAL
                            </div>
                        </h2>
                        <h3 className={classes.sectionSubtitle}>INFORMACIÓN DEL DONANTE</h3>
                        {/*<h4 className={classes.dangerText} >* CAMPOS OBLIGATORIOS</h4>*/}


                        {/*Facturacion Form - START*/}
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {false && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={billing}
                                            onChange={() => {
                                                setBilling(!billing)
                                                setPersonType('MORAL')
                                                if (billing === true) {
                                                    setPersonType('FISICA')
                                                }
                                            }}
                                            name="checkedB"
                                            color="primary"
                                            // disabled={!isBillingDisabled}
                                        />
                                    }
                                    label="Facturar"
                                />
                            )}
                            {/* {
                            personType === 'FISICA' && (
                                <div className={classes.conditionsNotMet}>
                                    IMPORTANTE: En caso de requerir la factura de tu donación, deberás dar de alta tus datos fiscales en el apartado de Detalles de la cuenta al iniciar sesión o registrarte antes de realizar el donativo o seleccionar tu donación como Persona Moral.
                                </div>
                            )
                        } */}
                        </div>
                        {/* <div className={classes.flexContainer} >
                        <div>
                            <label className="b-contain" >
                                <span style={{ color: '#333', fontWeight: 'normal', display: 'inline' }}></span>
                                <input type="radio"
                                    value="FISICA"
                                    checked={personType === 'FISICA'}
                                    onChange={(e) => {
                                        setPersonType(e.target.value)
                                        setBilling(false)
                                    }} />
                                PERSONA FÍSICA
                            <div className="b-input" style={{ padding: '8px' }} ></div>
                            </label>
                        </div>
                        <div>
                            <label className="b-contain" >
                                <span style={{ color: '#333', fontWeight: 'normal', display: 'inline' }}></span>
                                <input type="radio"
                                    value="MORAL"
                                    checked={personType === 'MORAL'}
                                    onChange={(e) => {
                                        setPersonType(e.target.value)
                                        setBilling(true)
                                    }} />
                                PERSONA MORAL
                            <div className="b-input" style={{ padding: '8px' }} ></div>
                            </label>
                        </div>
                    </div> */}
                        {/*Facturacion Form - END*/}


                        <div className={classes.formContainer} >

                            <div className={inputClasses.omrsInputGroup} >
                                <label className={inputClasses.omrsInputUnderlined}>
                                    <span className={inputClasses.omrsInputLabel}>Nombre</span>
                                    <input
                                        onChange={(e) => setName(e.target.value)}
                                        required/>
                                </label>
                            </div>

                            <div className={inputClasses.omrsInputGroup} >
                                <label className={inputClasses.omrsInputUnderlined}>
                                    <span className={inputClasses.omrsInputLabel}>Apellido Paterno</span>
                                    <input
                                        onChange={(e) => setLastNameF(e.target.value)}
                                        required/>

                                </label>
                            </div>

                            <div className={inputClasses.omrsInputGroup} >
                                <label className={inputClasses.omrsInputUnderlined}>
                                    <span className={inputClasses.omrsInputLabel}>Apellido Materno</span>
                                    <input
                                        onChange={(e) => setLastnameM(e.target.value)}
                                        required/>
                                </label>
                            </div>

                            <div className={inputClasses.omrsInputGroup} >
                                <label className={inputClasses.omrsInputUnderlined}>
                                    <span className={inputClasses.omrsInputLabel}>Correo Electrónico</span>
                                    <input
                                        onChange={(e) => setEmail(e.target.value)}
                                        required/>
                                </label>
                            </div>

                            {moralPersonFields}

                            <div className={inputClasses.omrsInputGroup} >
                                {/* <label className={inputClasses.omrsInputUnderlined} >
                                <input
                                    onChange={(e) => setPhone(e.target.value)}
                                    required />
                                <span className={inputClasses.omrsInputLabel} >Teléfono *</span>
                                </label> */}
                                <label className={inputClasses.omrsInputUnderlined} >
                                    <PhoneInput
                                        placeholder="Número de teléfono *"
                                        value={phone}
                                        onChange={setPhone}
                                        international
                                        defaultCountry='MX'
                                    />
                                    {/* <span className={inputClasses.omrsInputLabel} >Teléfono *</span> */}
                                </label>
                            </div>
                        </div>
                    </section>

                    {/* FORMA DE PAGO */}
                    <section>
                        <h2 className={classes.sectionTitle}>
                            <div className={classes.container_icon}>
                                <img src={pago} alt="pago"/> FORMA DE PAGO
                            </div>
                        </h2>
                        <h3 className={classes.sectionSubtitle}>SELECCIONA UN METODO DE PAGO</h3>
                        <div className={classes.flexContainer} >
                            {
                                principalCausePayment.map(payment => (
                                    <div key={payment.id}>
                                        <label className="b-contain" >
                                            <span style={{ color: '#333', fontWeight: 'normal', display: 'inline' }}></span>
                                            <input type="radio"
                                                   value={payment.name}
                                                   checked={paymentMethod === payment.name}
                                                   onChange={(e) => {
                                                       setPaymentMethod(e.target.value)
                                                       setPaymentId(payment.id)
                                                   }}
                                            />
                                            {
                                                payment.name === 'Tarjeta' ?
                                                    <>
                                                        {paymentMethod === 'Tarjeta' ? <img src={creditCardsColor} alt="creditcard" /> : <img src={crediCradsGray} alt="creditcard" />}
                                                    </>
                                                    :
                                                    <>
                                                        {paymentMethod === 'Paypal' ? <img src={paypalColor} alt="paypal" /> : <img src={paypalGray} alt="paypal" />}
                                                    </>
                                            }
                                            <div className="b-input" style={{ padding: '8px' }} ></div>
                                        </label>
                                    </div>
                                ))
                            }
                        </div>

                        {paymentMethodForm}

                        <div style={{ textAlign: 'left' }}><img src={verifiedLogo} alt="verisign" /></div>

                    </section>

                    {/* CONFIRMACION */}
                    <section style={{ textAlign: 'left' }}>
                        <p className={classes.text}>
                            Gracias por tu interés en el futuro de México, antes de concluir
                            con tu donativo lee y acepta nuestros términos y condiciones.
                        </p>
                        <div style={{marginTop:"10px"}}>
                            <label className="b-contain">
                            <span
                                className={classes.text}
                                style={{display: 'inline'}}
                            >
                                Acepto el <a className={classes.text} href="/privacidad-fonaton"
                                                           target="_blank" rel="noopener noreferrer">Aviso de Privacidad para Benefactores.</a>
                            </span>
                                <input
                                    onChange={() => setPrivacyFonaton(!privacyFonaton)}
                                    checked={privacyFonaton}
                                    type="checkbox"
                                />
                                <div className="b-input" style={{margin: '0px'}}></div>
                            </label>
                            <label className="b-contain">
                            <span
                                className={classes.text}
                                style={{display: 'inline'}}
                            >
                                Acepto el <a className={classes.text} href="https://merida.anahuac.mx/institucional/aviso-de-privacidad" target="_blank"
                                                          rel="noopener noreferrer">Aviso de Privacidad de la Universidad Anáhuac Mayab.</a>
                            </span>
                                <input
                                    onChange={() => setPrivacyAnahuac(!privacyAnahuac)}
                                    checked={privacyAnahuac}
                                    type="checkbox"
                                />
                                <div className="b-input" style={{margin: '0px'}}></div>
                            </label>
                            <label className="b-contain">
                                <span className={classes.text} style={{display: 'inline'}}>
                                    Acepto los <a className={classes.text} href="https://merida.anahuac.mx/institucional/terminos-y-condiciones-de-uso" target="_blank" rel="noopener noreferrer">
                                         términos, políticas y condiciones
                                    </a>.
                                </span>
                                <input
                                    onChange={() => setTerms(!terms)}
                                    checked={terms}
                                    type="checkbox"/>
                                <div className="b-input" style={{margin: '0px'}}></div>
                            </label>
                        </div>
                        <button
                            disabled={!isEnabled}
                            style={{margin: '50px 0px 10px 0px'}}
                            className={classes.submitButton}
                            onClick={paymentMethod === 'Tarjeta' ? handleCompleteDonation : handleCompleteDonationPayPal}>
                            <span style={{fontSize: '1.35em'}}>Completar donativo</span>
                        </button>
                    </section>
                    <section>
                        {(phisycalPersonCondition && personType === 'FISICA') ||
                            (phisycalPersonCondition && moralPersonCondition && personType === 'MORAL') ? null :
                                <div className={classes.conditionsNotMet}>Revise los campos de su información personal</div>
                        }
                        {
                            paymentCondition || paymentMethod === 'Paypal' ? null :
                                <div className={classes.conditionsNotMet}>Revise los campos de su información de pago</div>
                        }
                        {
                            donationAmount > 0 ? null :
                                <div className={classes.conditionsNotMet}>Su donativo tiene que ser mayor a $0.00</div>
                        }
                        {
                            privacyCondition ? null :
                                <div className={classes.conditionsNotMet}>Acepte los términos y condiciones</div>
                        }
                        {
                            donationType === 'UNICA' || (donationType === 'RECURRENTE' && recurrmentPlan) ? null :
                                <div className={classes.conditionsNotMet}>Revise la recurrencia de su pago</div>
                        }

                    </section>
                    <OrderSummary
                        amount={donationAmount}
                        donationType={donationType}
                        cause={principalCauseName}
                    />
                </div>
            </div>
            <Footer />
        </div>

    )
}

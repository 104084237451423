import React, { useState } from 'react'

import classes from './Sidebar.module.css'
import { useHistory } from 'react-router-dom'
import { IoIosMenu, IoMdClose } from 'react-icons/io'

import iconUserBlack from '../../UI/Assets/icon_user_black.svg'
import iconUserOrange from '../../UI/Assets/icon_user_orange.svg'
import iconPaymentBlack from '../../UI/Assets/icon_payment_black.svg'
import iconPaymentOrange from '../../UI/Assets/icon_payment_orange.svg'
import iconBillingOrange from '../../UI/Assets/icon_billing_orange.svg'
import iconBillingBlack from '../../UI/Assets/icon_billing_black.svg'

export default function Sidebar(props) {
    const [isVisible, setIsVisible] = useState(false)
    const history = useHistory()
    function handleLogout() {
        sessionStorage.clear()
        localStorage.clear()
        history.push('/')
        window.location.reload(false)
    }
    return(
            <div>
                <div className={classes.toggle} onClick={() => setIsVisible(!isVisible)} >
                    {isVisible ? <IoMdClose /> : <IoIosMenu />}
                </div>
                <div className={!isVisible ? [classes.main, classes.hidden].join(' ') : classes.main}>
                    <div className={[classes.row,classes.htoggle].join(' ')} style={{justifyContent:"end"}}>
                        <span className={classes.toggle} onClick={() => setIsVisible(!isVisible)} >
                            {isVisible ? <IoMdClose /> : <IoIosMenu />}
                        </span>
                    </div>
                    <div className={classes.row} style={{flexDirection:"column",justifyContent:"start",alignItems:"start"}}>
                        <span
                            className={props.activePath === '/perfil' ? classes.active : null}
                            onClick={()=> history.push('/perfil')}>
                            Inicio
                        </span>
                            <span
                                className={props.activePath === '/perfil/detalle' ? classes.active : null}
                                onClick={()=> history.push('/perfil/detalle')}>
                            Detalles de la cuenta
                        </span>
                            <span
                                className={props.activePath === '/perfil/pago' ? classes.active : null}
                                onClick={()=> history.push('/perfil/pago')}>
                            Métodos de pago
                        </span>
                            <span
                                className={props.activePath === '/perfil/facturacion' ? classes.active : null}
                                onClick={()=> history.push('/perfil/facturacion')}>
                            Facturación
                        </span>
                            <span
                                className={props.activePath === '/perfil/donar' ? classes.active : null}
                                onClick={()=> history.push('/perfil/donar')}>
                            ¡Quiero donar!
                        </span>
                    </div>

                    {/* <div className={classes.spacer} ></div> */}
                    <div className={classes.row}>
                        <span className={classes.button__nav} onClick={handleLogout}>
                        Cerrar sesión
                        </span>
                    </div>
                </div>
            </div>

    )
}